import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import Apontamento from "../Apontamento";
import { Redirect } from "react-router-dom";
import "./DetailTicket.css";
import { ToastConsumer } from "react-awesome-toasts";
import {
  CommentList,
  AdicionarComentario,
  ListaPendencias,
} from "../../components";
// import queryString from 'query-string';
const {
  getStatusName,
  getStatusClassName,
  getStatusClassNamePreTicket,
} = require("../../utils");

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

class DetailTicket extends Component {
  constructor(props) {
    super(props);
    this.getDetailsTicket = this.getDetailsTicket.bind(this);

    this.baixarAnexo = this.baixarAnexo.bind(this);
    this.handleClickCancelarTicket = this.handleClickCancelarTicket.bind(this);
    this.toggleModalCancelarPreTicket =
      this.toggleModalCancelarPreTicket.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.cancelarPreTicket = this.cancelarPreTicket.bind(this);

    this.state = {
      user: JSON.parse(this.props.user()),
      id: props.match.params.id,
      ticket: {
        cliente: {
          id: "",
          descricao: "",
          telefone: "",
          cnpj: "",
        },
        contato: {
          email: "",
          telefone: "",
        },
        tipo: "",
        assunto: "",
        descricao: "",
        prioridade: "",
        dataAgenda: "",
        notificarCliente: "",
        situacao: "",
        apontamentos: [{}],
        comentarios: [],
        logs: [],
        anexos: [],
      },
      actionsButtons: {
        iniciarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false,
        criarApontamento: false,
        cancelarAtendimento: false,
      },
      usuarioIniciouAtendimento: false,
      modal: false,
      actionModal: null,
      estorias: [],
      contatos: [],
      activeTab: "resumo",
      comentario: "",
      comentarioEditar: { comentario: "" },
      modalEditarComentario: false,
      pendencias: [],
      apontamentoAtual: { descricao: "", tipoEstoria: null },
      modalAvaliacao: false,
      rating: 1,
      comentarioAvaliacao: "",
      avaliacaoRequerida: false,
      modalAvaliar: false,
      pendenciaEditar: null,
      modalPendenciaEditar: false,
      refreshStatePendencia: false,
      modalConfirm: false,
      titleModal: "",
      modalApp: {
        alert: false,
      },
      buttonEditComment: false,
      fkTipoEstoriaSemRetorno: null,
      tempoTotal: null,
      finalizacaoSucesso: true,
      modalImpedimento: false,
      errors: {
        tipoEstoria: false,
        descricao: false,
        contato: false,
        descricaoComentario: false,
        descricaoComentarioEditar: false,
        projeto: false,
        obsCancelamento: false,
      },
      projetos: [],
      projeto: null,
      observacaoInterna: "",
      modalAprovarPreTicket: false,
      modalCancelarTicket: false,
      obsCancelamento: "",
      modalAlterarStatus: false,
    };
  }

  async componentDidMount() {
    await this.getDetailsTicket();
  }

  async getDetailsTicket() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/pre-tickets/${this.state.id}`,
        this.props.parameters()
      )
      .then(async (response) => {
        let ticket = response.data.ticket;

        if (ticket === null) {
          return this.props.history.push("/tickets");
        }

        ticket.descricao = ticket.descricao.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

        // ticket.anexos = [
        //   { desc: "Erro", dtGravacao: "2023-08-23T10:00:00.000Z" },
        // ];

        this.setState({
          ticket: ticket,
        });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  createMarkup(html) {
    return { __html: html };
  }

  async baixarAnexo(url) {
    setTimeout(() => {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }, 1000);
  }

  handleClickCancelarTicket() {
    this.toggleModalCancelarPreTicket();
  }

  toggleModalCancelarPreTicket() {
    this.setState({ modalCancelarTicket: !this.state.modalCancelarTicket });
  }

  handleChangeInput(value, type) {
    this.setState({ [type]: value });
  }

  async cancelarPreTicket({ toast }) {
    let obsCancelamento = this.state.obsCancelamento;

    if (obsCancelamento === "") {
      this.setState({
        errors: { ...this.state.errors, obsCancelamento: true },
      });
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/pre-tickets-cancelar`,
        { idTicket: this.state.id, obsCancelamento },
        this.props.parameters()
      )
      .then(async (response) => {
        const toastProps = {
          text: "Ticket cancelado",
          actionText: "Fechar",
          ariaLabel: "Ticket cancelado, clique para fechar",
        };

        toast.show({ ...toastProps, onActionClick: toast.hide });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  // render() {
  //   return (
  //     <div id="contentSite" className={className}>
  //       <div className="Example_container-home">
  //         <div className="container">
  //           <div className="Example_box">
  //             <div className="Example_box-content">
  //               <Col sm={12}>
  //                 <Nav tabs>
  //                   <NavItem>
  //                     <NavLink
  //                       className={{ active: this.state.activeTab === "resumo" }}
  //                       onClick={() => {
  //                         this.toggleTab("resumo");
  //                       }}
  //                     >
  //                       Resumo
  //                     </NavLink>
  //                   </NavItem>
  //                   <NavItem>
  //                     <NavLink
  //                       className={{ active: this.state.activeTab === "apontamentos" }}
  //                       onClick={() => {
  //                         this.toggleTab("apontamentos");
  //                       }}
  //                     >
  //                       Apontamentos
  //                     </NavLink>
  //                   </NavItem>

  //                   <NavItem>
  //                     <NavLink
  //                       className={{ active: this.state.activeTab === "comentarios" }}
  //                       onClick={() => {
  //                         this.toggleTab("comentarios");
  //                       }}
  //                     >
  //                       Conversação
  //                     </NavLink>
  //                   </NavItem>
  //                   <NavItem>
  //                     <NavLink
  //                       className={{ active: this.state.activeTab === "pendencias" }}
  //                       onClick={() => {
  //                         this.toggleTab("pendencias");
  //                       }}
  //                     >
  //                       Pendências
  //                     </NavLink>
  //                   </NavItem>
  //                   {this.state.ticket.tipo === 'Suporte' && <NavItem>
  //                     <NavLink
  //                       className={{ active: this.state.activeTab === "contestacao" }}
  //                       onClick={() => {
  //                         this.toggleTab("contestacao");
  //                       }}
  //                     >
  //                       Contestação
  //                     </NavLink>
  //                   </NavItem>}

  //                   {this.state.ticket.origemEmissao !== undefined && this.state.ticket.origemEmissao === 'portalCliente' && (
  //                   <React.Fragment>
  //                     <NavItem>
  //                       <NavLink
  //                         className={{ active: this.state.activeTab === 'anexos' }}
  //                         onClick={() => { this.toggleTab('anexos'); }}
  //                       >
  //                         Anexos
  //                       </NavLink>
  //                     </NavItem>
  //                     <NavItem>
  //                       <NavLink
  //                         className={{ active: this.state.activeTab === 'logs' }}
  //                         onClick={() => { this.toggleTab('logs'); }}
  //                       >
  //                         Logs
  //                       </NavLink>
  //                     </NavItem>
  //                   </React.Fragment>
  //                 )}

  //                 </Nav>

  //                 <TabContent activeTab={this.state.activeTab}>
  //                   <TabPane tabId="resumo">

  //                     <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>
  //                       <div className="item-detalhe-ticket">
  //                         <span className="item-detalhe-ticket-span">
  //                           <b>Código:</b> {this.state.ticket.codigo}{" "}
  //                         </span>
  //                         <b>Data Emissão:</b>{" "}
  //                         {moment(
  //                           new Date(this.state.ticket.dataCriacao)
  //                         ).format("DD/MM/YYYY")}
  //                       </div>

  //                       <div className="item-detalhe-ticket">
  //                         <span className="item-detalhe-ticket-span">
  //                           {" "}
  //                           <b>Tipo:</b> {this.state.ticket.tipo}
  //                         </span>
  //                       </div>

  //                       <div className="item-detalhe-ticket">
  //                         <span className="item-detalhe-ticket-span">
  //                           <b>Contato:</b>{" "}
  //                           {this.state.ticket.contato.descricao} (
  //                           {this.state.ticket.contato.email}){" "}
  //                         </span>
  //                       </div>

  //                       <div ref={this.myRef} id="teste" className="item-detalhe-ticket">
  //                         <b>Situação:</b>
  //                         <span
  //                           style={{
  //                             width: "100%",
  //                             fontWeight: "bold",
  //                             paddingLeft: "3px"
  //                           }}
  //                         >
  //                           <span
  //                             className={
  //                               "tag " +
  //                               getStatusClassName(this.state.ticket.situacao)
  //                             }
  //                           >
  //                             {getStatusName(this.state.ticket.situacao)}
  //                           </span>
  //                         </span>
  //                       </div>

  //                       <div className="item-detalhe-ticket">
  //                         <b>Usuário Atendimento:</b>{" "}
  //                         {this.state.ticket.usuario
  //                           ? this.state.ticket.usuario.descricao
  //                           : "Não Vinculado"}
  //                       </div>
  //                       <div className="item-detalhe-ticket">
  //                         <b>Assunto:</b> {this.state.ticket.assunto}
  //                       </div>
  //                       <div className="item-detalhe-ticket">
  //                         <b>Total de Horas : </b>{" "}
  //                         {this.state.exibeFracao
  //                           ? this.state.tempoTotal
  //                           : "Aguarde a finalização do atendimento."}
  //                       </div>
  //                       <div
  //                         className="item-detalhe-ticket"
  //                         dangerouslySetInnerHTML={this.createMarkup(
  //                           "<b>Descrição:</b> <br>" +
  //                             this.state.ticket.descricao
  //                         )}
  //                       />
  //                       <div
  //                         style={{
  //                           textDecoration: "underline",
  //                           cursor: "pointer",
  //                           paddingTop: "10px"
  //                         }}
  //                       >

  //                       </div>

  //                       {this.state.ticket.exibirBotao &&
  //                         <React.Fragment>
  //                           {this.state.ticket.permitirContestar ?
  //                             (
  //                               <button
  //                                 className="btn btn-danger"
  //                                 onClick={this.toggleModalContestacao}
  //                               >
  //                                 <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
  //                                 Contestar
  //                               </button>

  //                             ) : (
  //                               <span
  //                                 className="btn btn-danger span-btn"
  //                                 id="btn-contestar"
  //                               >
  //                                 <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
  //                                 Contestar
  //                               </span>
  //                             )
  //                           }

  //                           {this.state.ticket.mensagemBloqueio !== '' &&
  //                             <UncontrolledTooltip placement="right" target='btn-contestar'>
  //                               {this.state.ticket.mensagemBloqueio}
  //                             </UncontrolledTooltip>
  //                           }

  //                           <Modal isOpen={this.state.modalContestacao} toggle={this.toggleModalContestacao} className="modal-bg">

  //                             <div className="modal-header modal-footer-bg" toggle={this.toggleModalContestacao} style={{ fontSize: "15px" }}>
  //                               Contestar Ticket {this.state.ticket.codigo}
  //                               <button type="button" className="close" data-dismiss="modal" aria-label="Fechar" onClick={this.toggleModalContestacao}><i className="fa fa-times" aria-hidden="true"></i></button>
  //                             </div>
  //                             <div
  //                               className="modal-body"
  //                               style={{ overflowY: "scroll", maxHeight: "220px", fontSize: "15px", minHeight: "220px" }}
  //                             >
  //                               <FormGroup>
  //                                 <Label>Contestação:</Label>
  //                                 <textarea
  //                                   rows={5}
  //                                   className={this.state.errors.mensagemContestacao ? "form-control is-invalid" : "form-control"}
  //                                   type="text"
  //                                   value={this.state.mensagemContestacao}
  //                                   onChange={this.onChangeMensagemContestacao}
  //                                 />

  //                                 {this.state.errors.mensagemContestacao  &&
  //                                   <div className="is-invalid-feedback">
  //                                     Por favor digite uma mensagem.
  //                                   </div>
  //                                 }
  //                               </FormGroup>
  //                             </div>
  //                             <ModalFooter className="modal-footer-bg">

  //                               <ToastConsumer>
  //                                 {
  //                                   ({ show, hide }) => (
  //                                     <Button
  //                                       color="danger"
  //                                       onClick={() => this.onClickEnviarContestacao({toast: {show, hide}})}
  //                                     >
  //                                       <i className="fa fa-exclamation-circle" aria-hidden="true"></i> Contestar
  //                                     </Button>
  //                                   )
  //                                 }
  //                               </ToastConsumer>

  //                               <Button color="secondary" onClick={this.toggleModalContestacao}>Cancelar</Button>
  //                             </ModalFooter>
  //                           </Modal>
  //                         </React.Fragment>
  //                       }

  //                     </div>
  //                   </TabPane>

  //                   <TabPane tabId="apontamentos">
  //                     {this.state.load &&
  //                       <Apontamento
  //                         apontamentos={this.state.ticket.apontamentos}
  //                         createMarkup={this.createMarkup}
  //                         tempoTotal={this.state.tempoTotal}
  //                         tipoTicket={this.state.ticket.tipo}
  //                       />
  //                     }
  //                   </TabPane>
  //                   <TabPane tabId="comentarios">

  //                     <ToastConsumer>
  //                       {
  //                         ({ show, hide }) => (
  //                           <React.Fragment>
  //                             <CommentList
  //                               comentarios={this.state.ticket.comentarios}
  //                               onChangeCommentModalEditar={this.onChangeCommentModalEditar}
  //                               toggleModalEditarComentario={this.toggleModalEditarComentario}
  //                               onClickEditComment={this.onClickEditComment}
  //                               modalEditarComentario={this.state.modalEditarComentario}
  //                               comentarioEditar={this.state.comentarioEditar}
  //                               editarComentario={() => this.editarComentario({ toast: { show, hide } })}
  //                               user={this.state.user}
  //                               buttonEditComment={this.state.buttonEditComment}
  //                               errors={this.state.errors}
  //                             />

  //                             <AdicionarComentario
  //                               comentario={this.state.comentario}
  //                               adicionarComentarios={() => this.adicionarComentarios({ toast: { show, hide } })}
  //                               onChangeComment={this.onChangeComment}
  //                               errors={this.state.errors}
  //                             />
  //                           </React.Fragment>
  //                         )
  //                       }
  //                     </ToastConsumer>

  //                   </TabPane>
  //                   <TabPane tabId="pendencias">
  //                     {this.state.load &&
  //                       <ListaPendencias
  //                         pendencias={this.state.pendencias}
  //                       />
  //                     }
  //                   </TabPane>
  //                   <TabPane tabId="contestacao">
  //                     {this.state.load &&
  //                       <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>

  //                         {this.state.ticket.contestacao === null &&
  //                           <div
  //                           style={{
  //                             textAlign: "center",
  //                             padding: "15px"
  //                           }}
  //                         >
  //                           Ticket não possui contestação
  //                         </div>
  //                         }

  //                         {this.state.ticket.contestacao !== null &&

  //                           <React.Fragment>

  //                             <div className="item-detalhe-ticket-contestacao">
  //                               <span className="item-detalhe-ticket-span">
  //                                 {" "}
  //                                 <b>Situação:</b>
  //                                 <span
  //                                   style={{
  //                                     width: "100%",
  //                                     fontWeight: "bold",
  //                                     paddingLeft: "3px"
  //                                   }}
  //                                 >
  //                                   <span
  //                                     className={
  //                                       "tag "+
  //                                       (this.state.ticket.contestacao.aceita === null ? 'analise' : this.state.ticket.contestacao.aceita ? 'aceita': 'recusada')
  //                                     }
  //                                   >
  //                                     {this.state.ticket.contestacao.aceita === null ? 'Análise' : this.state.ticket.contestacao.aceita ? 'Aceita' : 'Recusada'}
  //                                   </span>
  //                                 </span>
  //                               </span>
  //                             </div>

  //                             <div className="item-detalhe-ticket-contestacao">
  //                               <span className="item-detalhe-ticket-span">
  //                                 {" "}
  //                                 <b>Data Emissão:</b> {moment(this.state.ticket.contestacao.data).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}
  //                               </span>
  //                             </div>

  //                             <div className="item-detalhe-ticket-contestacao" dangerouslySetInnerHTML={this.createMarkup('<b>Contestação:</b> <br>' + this.state.ticket.contestacao.mensagem)} />

  //                             {this.state.ticket.contestacao.mensagemAnalise !== '' && this.state.ticket.contestacao.mensagemAnalise !== undefined &&
  //                                <div className="item-detalhe-ticket-contestacao" dangerouslySetInnerHTML={this.createMarkup('<b>Resposta:</b> <br>' + this.state.ticket.contestacao.mensagemAnalise)} />

  //                             }
  //                           </React.Fragment>
  //                         }

  //                       </div>
  //                     }
  //                   </TabPane>

  //                   {this.state.ticket.anexos !== undefined && (
  //                  <TabPane tabId="anexos">
  //                     <div style={{ paddingTop: "15px" }}>
  //                     <table className="table table-bordered table-striped table-hover">
  //                       <thead className="thead-dark-custom thead-site">
  //                         <th style={{ borderTop: "none" }}>Descrição</th>
  //                         <th
  //                           style={{
  //                             borderTop: "none",
  //                             textAlign: "center",
  //                           }}
  //                         >
  //                           Data
  //                         </th>
  //                         <th className="text-center">Ações</th>
  //                       </thead>
  //                       <tbody>
  //                         {this.state.ticket.anexos.length > 0 &&
  //                           this.state.ticket.anexos.map((anexo, key) => (
  //                             <tr key={key}>
  //                               <td>{anexo.desc}</td>
  //                               <td className="text-center">
  //                                 {moment(anexo.dtGravacao).format(
  //                                   "DD/MM/YYYY HH:mm"
  //                                 )}
  //                               </td>
  //                               <td className="text-center">
  //                                 <div style={{ margin: "3px" }}>
  //                                   <i
  //                                     style={{
  //                                       fontSize: "22px",
  //                                       cursor: "pointer",
  //                                       color:"#28a745"
  //                                     }}
  //                                     className="fa fa-download"
  //                                     onClick={() => {
  //                                       this.baixarAnexo(anexo.url)
  //                                     }}
  //                                   ></i>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                           ))}
  //                       </tbody>
  //                     </table>
  //                  </div>
  //                </TabPane>
  //                )}
  //                {this.state.ticket.logs !== undefined && (
  //                  <TabPane tabId="logs">
  //                     <div style={{ paddingTop: "15px" }}>
  //                    <table className="table table-bordered table-striped table-hover">
  //                      <thead className="thead-dark-custom thead-site">
  //                        <th style={{ borderTop: "none" }}>Descrição</th>
  //                        <th
  //                          style={{
  //                            borderTop: "none",
  //                            textAlign: "center",
  //                          }}
  //                        >
  //                          Data
  //                        </th>
  //                      </thead>
  //                      <tbody>
  //                        {this.state.ticket.logs.length > 0 &&
  //                          this.state.ticket.logs.map((log, key) => (
  //                            <tr key={key}>
  //                              <td>{log.desc}</td>
  //                              <td className="text-center">
  //                                {moment(log.dtGravacao).format(
  //                                  "DD/MM/YYYY HH:mm"
  //                                )}
  //                              </td>
  //                            </tr>
  //                          ))}
  //                      </tbody>
  //                    </table>
  //                  </div>
  //                </TabPane>
  //                )}

  //                   <Link to={"/tickets"} style={{ color: "inherit", paddingTop: '15px' }}>
  //                     <i class="fa fa-arrow-left" aria-hidden="true" />{" "}
  //                     Voltar para listagem
  //                   </Link>
  //                 </TabContent>
  //               </Col>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    return (
      <div id="contentSite">
        <div className="container">
          <div className="Example_box">
            {/* <div className="Example_box-header">
              <span>Detalhe do Pré-Ticket</span>
            </div> */}
            {/* <div className="Example_box-content"> */}
            <div className="Example_box-content">
              <Col sm={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "resumo" }}
                      onClick={() => {
                        this.toggleTab("resumo");
                      }}
                    >
                      Resumo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "anexos" }}
                      onClick={() => {
                        this.toggleTab("anexos");
                      }}
                    >
                      Anexos
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "logs" }}
                      onClick={() => {
                        this.toggleTab("logs");
                      }}
                    >
                      Logs
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="resumo">
                    <div style={{ paddingTop: "15px" }}>
                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Código: </b>
                            {this.state.ticket.codigo}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Data Emissão: </b>
                            {moment(this.state.ticket.dataCriacao).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Situação: </b>
                            <span
                              style={{
                                width: "100%",
                                fontWeight: "bold",
                                paddingLeft: "3px",
                              }}
                            >
                              <span
                                className={
                                  "tag " +
                                  getStatusClassNamePreTicket(
                                    this.state.ticket.situacaoPortalCliente
                                  )
                                }
                              >
                                {this.state.ticket.situacaoPortalCliente}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Contato: </b>
                            {this.state.ticket.contato.descricao} (
                            {this.state.ticket.contato.email})
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Telefone: </b>
                            {this.state.ticket.contato.fone}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipoPortalCliente}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tela/Processo: </b>
                            {this.state.ticket.telaProcesso !== undefined
                              ? this.state.ticket.telaProcesso
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Módulo: </b>
                            {this.state.ticket.modulo !== undefined &&
                            this.state.ticket.modulo !== null
                              ? this.state.ticket.modulo
                              : ""}
                          </div>
                        </div>
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipo}
                          </div>
                        </div> */}
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Prioridade: </b>
                            {this.state.ticket.prioridade}
                          </div>
                        </div> */}
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Usuário Atendimento:</b>
                            {this.state.ticket.usuario
                              ? this.state.ticket.usuario.descricao
                              : "Não Vinculado"}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Assunto:</b>
                            {this.state.ticket.assunto}
                          </div>
                        </div>
                      </div>

                      {/* <div className="item-detalhe-ticket">
                        <b>Usuário Atendimento:</b>{" "}
                        {this.state.ticket.usuario
                          ? this.state.ticket.usuario.descricao
                          : "Não Vinculado"}
                      </div> */}

                      {/* <div className="item-detalhe-ticket">
                        <b>Assunto:</b> {this.state.ticket.assunto}
                      </div> */}

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Descrição:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.descricao
                              )}
                            />
                          </div>
                        </div>
                      </div>

                      {this.state.ticket.situacaoPortalCliente ===
                        "Cancelado" && (

                      <div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Obs Cancelamento:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.obsCancelamentoPortalCliente
                              )}
                            />
                          </div>
                        </div>
                      </div>
                        )}

                      {/* <div
                        className="item-detalhe-ticket"
                        dangerouslySetInnerHTML={this.createMarkup(
                          "<b>Descrição:</b> <br>" + this.state.ticket.descricao
                        )}
                      /> */}


                      <div style={{ paddingTop: "20px" }}>
                        <Button
                          color="danger"
                          onClick={() => this.handleClickCancelarTicket()}
                          style={{ margin: "3px", minWidth: "105px" }}
                          disabled={
                            this.state.ticket.situacaoPortalCliente ===
                            "Cancelado"
                          }
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>{" "}
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tabId="anexos">
                    <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                          <th className="text-center">Ações</th>
                        </thead>
                        <tbody>
                          {this.state.ticket.anexos.length > 0 &&
                            this.state.ticket.anexos.map((anexo, key) => (
                              <tr key={key}>
                                <td>{anexo.desc}</td>
                                <td className="text-center">
                                  {moment(anexo.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td className="text-center">
                                  <div style={{ margin: "3px" }}>
                                    <i
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                        color: "#28a745",
                                      }}
                                      className="fa fa-download"
                                      onClick={() => {
                                        this.baixarAnexo(anexo.url);
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                  <TabPane tabId="logs">
                    <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                        </thead>
                        <tbody>
                          {this.state.ticket.logs.length > 0 &&
                            this.state.ticket.logs.map((log, key) => (
                              <tr key={key}>
                                <td>{log.desc}</td>
                                <td className="text-center">
                                  {moment(log.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPane>

                  <br />

                  <Link
                    to={"/tickets"}
                    style={{ color: "inherit", paddingTop: "15px" }}
                  >
                    <i class="fa fa-arrow-left" aria-hidden="true" /> Voltar
                    para listagem
                  </Link>
                </TabContent>
              </Col>
            </div>
          </div>
        </div>

        <ToastConsumer>
          {({ show, hide }) => (
            <Modal
              isOpen={this.state.modalCancelarTicket}
              // isOpen={true}
              toggle={this.toggleModalCancelarPreTicket}
            >
              <ModalBody>
                <p style={{ fontSize: "18px" }}>
                  Tem certeza que deseja cancelar este ticket?
                </p>

                <div className="form-group">
                  <label>Obs Cancelamento:</label>
                  <textarea
                    value={this.state.obsCancelamento}
                    onChange={(e) =>
                      this.handleChangeInput(e.target.value, "obsCancelamento")
                    }
                    rows={4}
                    className={
                      this.state.errors.obsCancelamento
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {this.state.errors.obsCancelamento && (
                    <div className="is-invalid-feedback">
                      Por favor digite uma obs.
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={this.toggleModalCancelarPreTicket}
                >
                  Não
                </Button>
                <Button
                  color="danger"
                  onClick={() =>
                    this.cancelarPreTicket({
                      toast: { show, hide },
                    })
                  }
                >
                  Sim
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ToastConsumer>
      </div>
    );
  }
}

export default DetailTicket;
