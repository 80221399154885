import React, { Fragment } from "react";
import { Button, Table } from "reactstrap";

const ListaPendencias = props => {
  const dataFormat = (data) => {
    let aux = data.split("T")[0].split("-");
    data = aux[2]+"/"+aux[1]+"/"+aux[0];
    return data;
  }

  return (
    <Fragment>
      <Table striped hover bordered style={{ marginTop: "15px", marginBottom: "20px" }}>
        <thead className="thead-dark-custom thead-site">
          <tr>
            <th  >Assunto</th>
            <th style={{ maxWidth: "50px" }} >Tipo</th>
            <th style={{ maxWidth: "50px" }} >Atendente</th>
            <th style={{ maxWidth: "40px" }}>Emissão</th>
            <th style={{ maxWidth: "35px" }} >Ações</th>
          </tr>
        </thead>
        <tbody style={{color:'black!important'}}>
          {props.pendencias.length > 0 && props.pendencias.map((pendencia, index) =>
            <tr key={index} style={{ color: '#FFF!important' }}>
              <td>{pendencia.assunto}</td>
              <td style={{ maxWidth: "50px" }} >{pendencia.pendencia}</td>
              <td style={{ maxWidth: "50px" }} >{pendencia.usuario.descricao}</td>
              <td style={{ maxWidth: "40px" }} >{dataFormat(pendencia.dataCriacao)}</td>
              <td style={{ maxWidth: "35px" }}>
                <a href={`/tickets/detail/${pendencia._id}`}>
                  <Button color="info" style={{ margin: '5px', color: '#FFF!important' }}> <i className="fa fa-eye" aria-hidden="true"></i></Button>
                </a>
              </td>
            </tr>
          )}
         
        </tbody>
      </Table>
      {props.pendencias.length === 0 && (
        <div className="mensagem-sem-registros">
          No momento este ticket não possui nenhuma pendência
        </div>
      )}
    </Fragment>
  );
};

export default ListaPendencias;
