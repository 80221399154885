import React, { Component } from "react";
import api from "../../services/api";
import validator from "validator";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledTooltip,
} from "reactstrap";
import "./Notificacao.css";
import axios from "axios";
import Select from "react-select";
import { ToastConsumer } from "react-awesome-toasts";
import moment from "moment";
import Pagination from "react-js-pagination";

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

class Notificacao extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.loadNotificacoes = this.loadNotificacoes.bind(this);
    this.onClickArquivar = this.onClickArquivar.bind(this);
    this.calculaTempo = this.calculaTempo.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.defineTipoIcone = this.defineTipoIcone.bind(this);
    this.loadNotificacoesArquivadas =
      this.loadNotificacoesArquivadas.bind(this);
    this.paginarArquivadas = this.paginarArquivadas.bind(this);

    this.state = {
      notificacoes: [],
      notificacoesAnteriores: [],
      notificacoesArquivadas: [],
      activeTab: "1",
      existeNotificacao: false,
      background: "",
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
      paginacaoArquivadas: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
      executouLoadNotificacoesArquivadas: false,
      loading: true,
      loadingArquivadas: true,
    };
  }

  async componentWillUnmount() {
    document.body.style = this.state.background;
  }

  async componentDidMount() {
    this.setState(
      { background: document.body.style },
      () => (document.body.style = "background: #FFF !important;")
    );
    localStorage.setItem("existeNotificacao", false);
    await this.loadData();
    let notificacoes = this.state.notificacoes
      .concat(this.state.notificacoesAnteriores)
      .concat(this.state.notificacoesArquivadas);
    notificacoes = notificacoes.filter((not) => {
      if (not.visualizado !== true) {
        return not._id;
      }
    });

    if (notificacoes.length) {
      notificacoes = notificacoes.map((notificacao) => notificacao._id);
      this.notificacoesVisualizar(notificacoes);
    }
  }

  async loadData() {
    await this.loadNotificacoes(true);
  }

  async loadNotificacoes(executarInterval = false) {
    this.setState({ loading: true });
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/notificacoes?pagina=${
          this.state.paginacao.pagina
        }`,
        this.props.parameters()
      )
      .then((response) => {
        let { notificacoes, notificacoesAnteriores } = response.data;

        for (let notificacao of notificacoes) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        for (let notificacao of notificacoesAnteriores) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.iconeDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        let existeNotificacao = this.state.existeNotificacao;

        if (notificacoes.length > 0 || notificacoesAnteriores.length > 0) {
          existeNotificacao = true;
        }

        let paginacao = this.state.paginacao;

        if (response.data.paginacao !== null) {
          paginacao = {
            pagina: this.state.paginacao.pagina,
            limite: response.data.paginacao.limite,
            totalItens: response.data.paginacao.totalItens,
          };
        }

        this.setState(
          {
            notificacoes,
            notificacoesAnteriores,
            existeNotificacao,
            paginacao,
            loading: false,
          },
          () => {
            if (executarInterval) {
              setInterval(() => {
                let notificacoesMinuto = this.state.notificacoes;
                let notificacoesAnterioresMinuto =
                  this.state.notificacoesAnteriores;
                for (let notificacao of notificacoesMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                for (let notificacao of notificacoesAnterioresMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                this.setState({
                  notificacoes: notificacoesMinuto,
                  notificacoesAnteriores: notificacoesAnterioresMinuto,
                });
              }, 60000); // 1 minuto
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        // this.props.handlingErrorsApi(err, this.props)
      });
  }

  async loadNotificacoesArquivadas(executarInterval = false) {
    this.setState({ loadingArquivadas: true });
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/notificacoes-arquivadas?pagina=${
          this.state.paginacaoArquivadas.pagina
        }`,
        this.props.parameters()
      )
      .then((response) => {
        let notificacoesArquivadas = response.data.notificacoesArquivadas;

        for (let notificacao of notificacoesArquivadas) {
          notificacao.tempo = this.calculaTempo(notificacao.data);
          let { icone, iconeDescricao, urlDescricao } = this.defineTipoIcone(
            notificacao.tipo
          );
          notificacao.icone = icone;
          notificacao.urlDescricao = iconeDescricao;
          notificacao.urlDescricao = urlDescricao;
        }

        let paginacao = this.state.paginacaoArquivadas;

        if (response.data.paginacao !== null) {
          paginacao = {
            pagina: this.state.paginacao.pagina,
            limite: response.data.paginacao.limite,
            totalItens: response.data.paginacao.totalItens,
          };
        }

        this.setState(
          {
            notificacoesArquivadas,
            paginacaoArquivadas: paginacao,
            loadingArquivadas: false,
          },
          () => {
            if (executarInterval) {
              setInterval(() => {
                let notificacoesArquivadasMinuto =
                  this.state.notificacoesArquivadas;

                for (let notificacao of notificacoesArquivadasMinuto) {
                  notificacao.tempo = this.calculaTempo(notificacao.data);
                  let { icone, iconeDescricao, urlDescricao } =
                    this.defineTipoIcone(notificacao.tipo);
                  notificacao.icone = icone;
                  notificacao.iconeDescricao = iconeDescricao;
                  notificacao.urlDescricao = urlDescricao;
                }
                this.setState({
                  notificacoesArquivadas: notificacoesArquivadasMinuto,
                });
              }, 60000); // 1 minuto
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
        // this.props.handlingErrorsApi(err, this.props)
      });
  }

  async onClickArquivar(idNotificacao = null) {
    let listaNotificacoes = [];

    if (idNotificacao === null) {
      let lista = this.state.notificacoes.concat(
        this.state.notificacoesAnteriores
      );

      listaNotificacoes = lista.map((item) => item._id);
    } else {
      listaNotificacoes.push(idNotificacao);
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/arquivar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then((response) => {
        this.loadNotificacoes();
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  calculaTempo(dataEmissao) {
    let dataAtual = moment().utc();
    let dataNotificacao = moment(dataEmissao).utc();
    let ms = dataAtual.diff(dataNotificacao);
    let duration = moment.duration(ms);
    let totalMinutos = Math.floor(duration.asMinutes());

    let totalHoras = 0;
    let totalDias = 0;

    if (totalMinutos === 0) {
      return `Há alguns instantes`;
    }

    if (totalMinutos > 59) {
      totalHoras = parseInt(totalMinutos / 60);
    } else {
      return `Há ${totalMinutos} ${totalMinutos === 1 ? "minuto" : "minutos"}`;
    }

    if (totalHoras > 23) {
      totalDias = parseInt(totalHoras / 24);
      return `Há ${totalDias} ${totalDias === 1 ? "dia" : "dias"}`;
    } else {
      return `Há ${totalHoras} ${totalHoras === 1 ? "hora" : "horas"}`;
    }
  }

  toggleTab(tab) {
    if (tab === "2") {
      if (!this.state.executouLoadNotificacoesArquivadas) {
        this.setState({ executouLoadNotificacoesArquivadas: true }, () => {
          this.loadNotificacoesArquivadas(true);
        });
      }
    }

    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  defineTipoIcone(tipo) {
    let icone = "";
    let iconeDescricao = "";
    let urlDescricao = "";

    if (tipo === "novoComentario") {
      icone = "fa-commenting";
      iconeDescricao = "Comentário";
      urlDescricao = "Abrir Ticket";
    } else if (tipo === "contestacaoAvaliada") {
      icone = "fa-exclamation-circle";
      iconeDescricao = "Contestação";
      urlDescricao = "Abrir Ticket";
    } else if (
      tipo === "requisitoAprovacao" ||
      tipo === "requisitoCancelamento"
    ) {
      icone = "fa-list-ul";
      iconeDescricao = "Requisito";
      urlDescricao = "Abrir Requisito";
    } else {
      icone = "fa-ticket";
      iconeDescricao = "Ticket";
      urlDescricao = "Abrir Ticket";
    }
    return { icone, iconeDescricao, urlDescricao };
  }

  async notificacoesVisualizar(listaNotificacoes) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/notificacoes/visualizar`,
        { listaNotificacoes },
        this.props.parameters()
      )
      .then((response) => {})
      .catch((err) => this.props.handlingErrorsApi(err, this.props));

    return true;
  }

  paginar(pagina = 1) {
    this.setState(
      {
        paginacao: { ...this.state.paginacao, pagina: pagina },
        notificacoes: [],
        notificacoesAnteriores: [],
      },
      async () => {
        await this.loadNotificacoes();
      }
    );
  }

  paginarArquivadas(pagina = 1) {
    this.setState(
      {
        paginacaoArquivadas: {
          ...this.state.paginacaoArquivadas,
          pagina: pagina,
        },
        notificacoesArquivadas: [],
      },
      async () => {
        await this.loadNotificacoesArquivadas();
      }
    );
  }

  render() {
    return (
      <div>
        <div id="contentSite" className={className}>
          <div className="container">
            <div
              className="offset-sm-1 col-sm-9"
              style={{ paddingTop: "15px" }}
            >
              <Nav tabs>
                <NavItem style={{ cursor: "pointer" }}>
                  <NavLink
                    className={{ active: this.state.activeTab === "1" }}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Notificações
                  </NavLink>
                </NavItem>
                <NavItem style={{ cursor: "pointer" }}>
                  <NavLink
                    className={{ active: this.state.activeTab === "2" }}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Arquivadas
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.loading && (
                    <table
                      className="table table-hover table-striped"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="container-loading">
                        <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                          <div class="ldio-jz4kbaf040e">
                            <div></div>
                            <div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </table>
                  )}

                  {this.state.notificacoes.length === 0 &&
                    this.state.notificacoesAnteriores.length === 0 &&
                    !this.state.loading && (
                      <div className="nenhuma-notificacao">
                        Você ainda não possui nenhuma notificação
                      </div>
                    )}

                  {this.state.notificacoes.length > 0 && !this.state.loading && (
                    <React.Fragment>
                      <div className="box-notificacao">
                        <div className="header-notificacao">
                          <span>Hoje</span>
                        </div>

                        {this.state.notificacoes.length > 0 &&
                          this.state.notificacoes.map((notificacao) => (
                            <div
                              className="item-notificacao"
                              key={notificacao._id}
                            >
                              <div className="aha">
                                <div className="tipo-notificacao">
                                  <i
                                    className={`fa ${notificacao.icone}`}
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "5px",
                                    }}
                                  ></i>{" "}
                                  {notificacao.iconeDescricao}
                                </div>

                                <div className="titulo-notificacao">
                                  {notificacao.titulo}
                                </div>
                                <div
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ float: "left", width: "100%" }}>
                                    {notificacao.descricao}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#6f7782",
                                      }}
                                    >
                                      {" "}
                                      {notificacao.tempo}
                                    </span>
                                  </div>
                                </div>
                                {notificacao.url !== null && (
                                  <div
                                    className="link-notificacao"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/${notificacao.url}`
                                      )
                                    }
                                  >
                                    {notificacao.urlDescricao}
                                  </div>
                                )}
                              </div>

                              <div
                                className="aha2"
                                onClick={() =>
                                  this.onClickArquivar(notificacao._id)
                                }
                                id={`btn-arquivar-${notificacao._id}`}
                              >
                                <div>
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={`btn-arquivar-${notificacao._id}`}
                                  >
                                    Arquivar notificação
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </React.Fragment>
                  )}

                  {this.state.notificacoesAnteriores.length > 0 && (
                    <React.Fragment>
                      <div
                        className="box-notificacao"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="header-notificacao">
                          <span>Anteriores</span>
                        </div>
                        {this.state.notificacoesAnteriores.map(
                          (notificacao) => (
                            <div
                              className="item-notificacao"
                              key={notificacao._id}
                            >
                              <div className="aha">
                                <div className="tipo-notificacao">
                                  <i
                                    className={`fa ${notificacao.icone}`}
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "5px",
                                    }}
                                  ></i>{" "}
                                  {notificacao.iconeDescricao}
                                </div>

                                <div className="titulo-notificacao">
                                  {notificacao.titulo}
                                </div>
                                <div
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div style={{ float: "left", width: "100%" }}>
                                    {notificacao.descricao}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#6f7782",
                                      }}
                                    >
                                      {" "}
                                      {notificacao.tempo}
                                    </span>
                                  </div>
                                </div>
                                {notificacao.url !== null && (
                                  <div
                                    className="link-notificacao"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/${notificacao.url}`
                                      )
                                    }
                                  >
                                    {notificacao.urlDescricao}
                                  </div>
                                )}
                              </div>

                              <div
                                className="aha2"
                                id={`btn-arquivar-${notificacao._id}`}
                                onClick={() =>
                                  this.onClickArquivar(notificacao._id)
                                }
                              >
                                <div>
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement="right"
                                    target={`btn-arquivar-${notificacao._id}`}
                                  >
                                    Arquivar notificação
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  <div
                    className="noprint"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      float: "left",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    {this.state.paginacao.totalItens > 0 &&
                      !this.state.loading && (
                        <div className="paginator">
                          <Pagination
                            prevPageText={<i className="fa fa-angle-left" />}
                            nextPageText={<i className="fa fa-angle-right" />}
                            firstPageText={
                              <i className="fa fa-angle-double-left" />
                            }
                            lastPageText={
                              <i className="fa fa-angle-double-right" />
                            }
                            activePage={this.state.paginacao.pagina}
                            itemsCountPerPage={this.state.paginacao.limite}
                            totalItemsCount={this.state.paginacao.totalItens}
                            onChange={(pagina) => this.paginar(pagina)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  {this.state.loadingArquivadas && (
                    <table
                      className="table table-hover table-striped"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="container-loading">
                        <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                          <div class="ldio-jz4kbaf040e">
                            <div></div>
                            <div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </table>
                  )}

                  {this.state.notificacoesArquivadas.length === 0 &&
                    !this.state.loadingArquivadas && (
                      <div className="nenhuma-notificacao">
                        Você ainda não arquivou nenhuma notificação
                      </div>
                    )}
                  {this.state.notificacoesArquivadas.length > 0 &&
                    !this.state.loadingArquivadas && (
                      <React.Fragment>
                        <div
                          className="box-notificacao"
                          style={{ marginBottom: "40px" }}
                        >
                          <div className="header-notificacao">
                            <span>Arquivadas</span>
                          </div>

                          {this.state.notificacoesArquivadas.map(
                            (notificacao) => (
                              <div
                                className="item-notificacao"
                                key={notificacao._id}
                              >
                                <div className="aha">
                                  <div className="tipo-notificacao">
                                    <i
                                      className={`fa ${notificacao.icone}`}
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "16px",
                                        marginRight: "5px",
                                      }}
                                    ></i>{" "}
                                    {notificacao.iconeDescricao}
                                  </div>

                                  <div className="titulo-notificacao">
                                    {notificacao.titulo}
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div
                                      style={{ float: "left", width: "100%" }}
                                    >
                                      {notificacao.descricao}
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          color: "#6f7782",
                                        }}
                                      >
                                        {" "}
                                        {notificacao.tempo}
                                      </span>
                                    </div>
                                  </div>
                                  {notificacao.url !== "" && (
                                    <div
                                      className="link-notificacao"
                                      onClick={() =>
                                        this.props.history.push(
                                          `/${notificacao.url}`
                                        )
                                      }
                                    >
                                      {notificacao.urlDescricao}
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="aha2"
                                  onClick={() =>
                                    this.onClickArquivar(notificacao._id)
                                  }
                                  id={`btn-arquivar-${notificacao._id}`}
                                >
                                  <div>
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                      style={{ fontSize: "16px" }}
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="right"
                                      target={`btn-arquivar-${notificacao._id}`}
                                    >
                                      Ativar notificação
                                    </UncontrolledTooltip>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        <div
                          className="noprint"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            float: "left",
                            width: "100%",
                            paddingTop: "20px",
                          }}
                        >
                          {this.state.paginacaoArquivadas.totalItens > 0 &&
                            !this.state.loadingArquivadas && (
                              <div className="paginator">
                                <Pagination
                                  prevPageText={
                                    <i className="fa fa-angle-left" />
                                  }
                                  nextPageText={
                                    <i className="fa fa-angle-right" />
                                  }
                                  firstPageText={
                                    <i className="fa fa-angle-double-left" />
                                  }
                                  lastPageText={
                                    <i className="fa fa-angle-double-right" />
                                  }
                                  activePage={
                                    this.state.paginacaoArquivadas.pagina
                                  }
                                  itemsCountPerPage={
                                    this.state.paginacaoArquivadas.limite
                                  }
                                  totalItemsCount={
                                    this.state.paginacaoArquivadas.totalItens
                                  }
                                  onChange={(pagina) =>
                                    this.paginarArquivadas(pagina)
                                  }
                                  itemClass="page-item"
                                  linkClass="page-link"
                                />
                              </div>
                            )}
                        </div>
                      </React.Fragment>
                    )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notificacao;
