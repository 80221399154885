import React, { Component } from "react";
import { ListaApontamentos } from "../../components";
import { Col, Row } from "reactstrap";

class Apontamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apontamentos: this.props.apontamentos,
      descricaoAtual: "Clique sobre o apontamento para visualizar a descrição.",
      tipoTicket: this.props.tipoTicket
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div className="col-sm-12" style={{ paddingTop: "15px", paddingBottom: "20px" }}>
        <Row>
          <Col sm={12}>
            {this.props.apontamentos.length > 0 ? (
              <ListaApontamentos
                apontamentos={this.props.apontamentos}
                tipoTicket={this.state.tipoTicket}
                alteraDescricao={descricao =>
                  this.setState({
                    descricaoAtual: descricao.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br />"
                    )
                  })
                }
                descricao={this.state.descricaoAtual}
                createMarkup={this.props.createMarkup}
                tempoTotal={this.props.tempoTotal}
              />
            ) : (
              <div>No momento este ticket, não possui nenhum apontamento.</div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Apontamento;
