import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Login, Home, Requisito, Admin, Ticket, DetailTicket, Cobranca, Notificacao, TicketNovo, RequisitoNovo, DetailPreTicket} from './pages';
import { isAuthenticated, getToken, logout, getUser } from './auth';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import { ToastProvider } from 'react-awesome-toasts';
require('dotenv').config();

const getParametersApi = () => {
  return {
    headers: {
      'x-access-token': getToken()
    }
  };
}

const getBaseUrlApi = () => { 
  if(window.location.href.search('sac.priorize') !== -1) {     
    return process.env.REACT_APP_DEV_API_URL;
  }else{
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  } 
}

const handlingErrorsApi = (error, props) => {
  error = JSON.parse(error);
  console.log('error iss > ', error);
  let response = error.response;

  if(error.response){

  if (response.status && response.status === 401) {
    //logout();
    props.history.push('login');
  } else if (response.status && response.status === 422) {
    //logout();
    props.history.push('login');
  }else{
    console.log('error');
  }
}else{
  console.log(error);
  
  console.log('fatal error');
}
}

const detectIE = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    return true;
  }
  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    return true;
  }
  return false;
}

const teste = () => {
  let existeNotificacao = localStorage.getItem('existeNotificacao');
  
  if(existeNotificacao === null){
    localStorage.setItem('existeNotificacao', false);
    existeNotificacao = false;
  }
  
  return existeNotificacao  
}

const PrivateRoute = ({ component: Component, itemActive, titulo, icone, ...rest }) => {
  let existeNotificacao = teste();

  if(detectIE()){
    return (	
      <div className="container">
		    <div className="row">
          <div className="alert alert-warning" role="alert" style={{width:"100%"}}>
            <p><b>Alerta</b> : a versão do navegador de internet que você está utilizando não é suportada por esta aplicação ou é muito antiga.</p>
            <p>As versões recomendadas são : <a href="https://www.google.com/intl/pt-BR/chrome/" target="_blank" className="link-alerta">Google Chrome</a> e <a href="http://www.mozilla.org/pt-BR/firefox/" target="_blank" className="link-alerta">Firefox</a></p>
          </div>		
        </div>
      </div>
    )
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <div>
            <NavBar titulo={titulo} icone={icone}/>
            <SideBar 
              itemActive={itemActive} 
              parameters={getParametersApi}
              getBaseUrlApi={getBaseUrlApi} 
              handlingErrorsApi={handlingErrorsApi}
              existeNotificacao={existeNotificacao}
            />
            <Component {...props}
              parameters={getParametersApi}
              user={getUser}
              getBaseUrlApi={getBaseUrlApi}
              handlingErrorsApi={handlingErrorsApi}
              logout={logout}
            />
          </div>
        ) : (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          )
      }
    />
  )
}

const LoginRoute = ({ component: Component, ...rest }) => {
  if(detectIE()){
    return (	
      <div className="container">
		    <div className="row">
          <div className="alert alert-warning" role="alert" style={{width:"100%"}}>
            <p><b>Alerta</b> : a versão do navegador de internet que você está utilizando não é suportada por esta aplicação ou é muito antiga.</p>
            <p>As versões recomendadas são : <a href="https://www.google.com/intl/pt-BR/chrome/" target="_blank" className="link-alerta">Google Chrome</a> e <a href="http://www.mozilla.org/pt-BR/firefox/" target="_blank" className="link-alerta">Firefox</a></p>
          </div>		
        </div>
      </div>
    )
  }
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
        ) : (
          <Component {...props} parameters={getParametersApi} getBaseUrlApi={getBaseUrlApi} />
        )
      }
    />
  )
}

const LogoutRoute = ({ component: Component, ...rest }) => {
  logout();
  return (
    <Route
      {...rest}
      render={props =>
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      }
    />
  )
}

const NoMatch = ({ location }) => (
  <div>
    <center>
      <h3>404! <br />essa página não existe</h3>
    </center>
  </div>
);

const App = (props) => {
  return (
    <ToastProvider timeout={3000} position={'bottom-left'}>
      <BrowserRouter>
        <Switch>
          <LoginRoute path="/login" exact component={Login} />
          <LogoutRoute path="/logout" exact component={Login} />
          <PrivateRoute path="/" exact component={Home} titulo="Indicadores" itemActive="home" icone="fa-tachometer"/>
          <PrivateRoute path="/home" exact component={Home} titulo="Indicadores" itemActive="home" icone="fa-tachometer" />
          <PrivateRoute path="/requisitos" exact component={Requisito} titulo="Requisitos" itemActive="requisitos" icone="fa-list-ul" />
          {/* <PrivateRoute path="/requisitos/novo" exact component={RequisitoNovo} titulo="Novo Requisito" itemActive="requisitos" icone="fa-list-ul" /> */}
          <PrivateRoute path="/admin" exact component={Admin} titulo="Admin" itemActive="admin" icone="fa-cogs" />
          <PrivateRoute path="/tickets" exact component={Ticket} titulo="Tickets" itemActive="tickets" icone="fa-ticket" />
          <PrivateRoute path="/tickets/detail/:id" exact component={DetailTicket} titulo="Detalhe do Ticket" itemActive="tickets" icone="fa-ticket"/>
          <PrivateRoute path="/pre-tickets/detail/:id" exact component={DetailPreTicket} titulo="Detalhe do Ticket" itemActive="tickets" icone="fa-ticket"/>
          <PrivateRoute path="/tickets/novo" exact component={TicketNovo} titulo="Novo Ticket" itemActive="ticketsNovo" icone="fa-ticket"/>
          <PrivateRoute path="/cobrancas" exact component={Cobranca} titulo="Cobranças" itemActive="cobrancas" icone="fa-file" />
          <PrivateRoute path="/notificacoes" exact component={Notificacao} titulo="Notificações" itemActive="notificacao" icone="fa-bell" />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
