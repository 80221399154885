import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, TabPane, TabContent, Modal, ModalHeader, ModalBody, Label, FormGroup, ModalFooter, Button, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import axios from "axios";
import Apontamento from "../Apontamento";
import  { Redirect } from 'react-router-dom'
import "./DetailTicket.css";
import { ToastConsumer } from 'react-awesome-toasts';
import { CommentList, AdicionarComentario, ListaPendencias } from '../../components';
// import queryString from 'query-string';
const { getStatusName, getStatusClassName } = require("../../utils");

const className = localStorage.getItem('menuCompacto') !== null ? localStorage.getItem('menuCompacto') === 'true' ? 'contentSite-menu-compacto' : '' : '';

class DetailTicket extends Component {
  constructor(props) {
    super(props);
    this.getDetailsTicket = this.getDetailsTicket.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.onChangeCommentModalEditar = this.onChangeCommentModalEditar.bind(this);
    this.toggleModalEditarComentario = this.toggleModalEditarComentario.bind(this);
    this.onClickEditComment = this.onClickEditComment.bind(this);
    this.editarComentario = this.editarComentario.bind(this);
    this.adicionarComentarios = this.adicionarComentarios.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.toggleModalContestacao = this.toggleModalContestacao.bind(this);
    this.onChangeMensagemContestacao = this.onChangeMensagemContestacao.bind(this); 
    this.onClickEnviarContestacao = this.onClickEnviarContestacao.bind(this);
    this.selecionaAba = this.selecionaAba.bind(this);
    this.baixarAnexo = this.baixarAnexo.bind(this);

    this.state = {
      id: props.match.params.id,
      ticket: {
        contato: {
          email: ""
        },
        tipo: "",
        assunto: "",
        descricao: "",
        situacao: "",
        apontamentos: [],
        comentarios: []
      },
      tempoTotal: "00:00:00",
      exibeFracao: false,
      activeTab: "resumo",
      load: false,
      modalEditarComentario: false,
      comentarioEditar: { comentario: '' },
      buttonEditComment: false,
      user: JSON.parse(this.props.user()),
      errors: {
        tipoEstoria: false,
        descricao: false,
        contato: false,
        descricaoComentario: false,
        descricaoComentarioEditar: false,
        mensagemContestacao: false
      },
      pendencias: [],
      comentario: '',
      modalContestacao: false,
      mensagemContestacao: ''
    };
  }

  async componentDidMount() {
    this.selecionaAba();
    await this.getDetailsTicket();    
  }

  async getDetailsTicket() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/tickets/${this.state.id}`,
        this.props.parameters()
      )
      .then(async response => {
        let ticket = response.data.ticket;
        const pendencias = response.data.pendencias;

        if (ticket === null) {
          alert("Ticket inválido");
          return this.props.history.push("/home");
        }
        ticket.descricao = ticket.descricao.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

        if(ticket.contestacao !== null){
          ticket.contestacao.mensagem = ticket.contestacao.mensagem.replace(
            /(?:\r\n|\r|\n)/g,
            "<br />"
          );

          if(ticket.contestacao.mensagemAnalise !== undefined){
            ticket.contestacao.mensagemAnalise = ticket.contestacao.mensagemAnalise.replace(
              /(?:\r\n|\r|\n)/g,
              "<br />"
            );
          }
        }

        ticket.descricao = ticket.descricao.replace(
          /(?:\r\n|\r|\n)/g,
          "<br />"
        );

        let totalHoras = 0;
        let totalMinutos = 0;
        let tempoTotal = "";
        let exibeFracao = true;

        if (ticket.situacao === 4 && ticket.hasOwnProperty("tempoTotalPo")) {
          if (ticket.tempoTotalPo.minutos > 59) {
            totalHoras = parseInt(ticket.tempoTotalPo.minutos / 60);
            totalMinutos = ticket.tempoTotalPo.minutos - totalHoras * 60;
          } else {
            totalMinutos = ticket.tempoTotalPo.minutos;
          }

          tempoTotal =
            totalHoras.toString().padStart(2, "0") +
            ":" +
            totalMinutos.toString().padStart(2, "0") +
            ":" +
            ticket.tempoTotalPo.segundos.toString().padStart(2, "0");
        } else {
          exibeFracao = false;
          tempoTotal = "00:00:00";
        }

        await this.setState(
          { ticket, tempoTotal, exibeFracao, load: true, pendencias },
          async () => {}
        );
      })
      .catch(err => {
        //alert(JSON.stringify(err));
        //this.props.handlingErrorsApi(JSON.stringify(err), this.props)
      });
  }

  createMarkup(html) {
    return { __html: html };
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onChangeCommentModalEditar(e) {
    let value = e.target.value;
    let btnActive = false;
    if(value.length >= 7){
      btnActive = true;
    }
    let localComentario = this.state.comentarioEditar;
    localComentario.comentario = value;
    this.setState({ comentarioEditar: localComentario,buttonEditComment : btnActive});
  }

  async toggleModalEditarComentario() {
    await this.setState({ modalEditarComentario: !this.state.modalEditarComentario });
  }

  async onClickEditComment(comentario) {
    let btnActive = false;
    if(comentario.comentario.length >= 7){
      btnActive = true;
    }

    let errors = this.state.errors;
    errors.descricaoComentarioEditar = false;

    await this.setState({ comentarioEditar: Object.assign({}, comentario), buttonEditComment : btnActive, errors }, () => this.toggleModalEditarComentario());
  }

  async editarComentario({ toast }) {
    let comentario = this.state.comentarioEditar;

    let errors = this.state.errors;
    if(comentario.comentario.length < 7 || comentario.comentario === ''){
      errors.descricaoComentarioEditar = true;
      this.setState({errors});
      return false;

    }else{
      errors.descricaoComentarioEditar = false;
      this.setState({errors});
    }

    await axios.put(`${this.props.getBaseUrlApi()}/comentarios/${this.state.id}/${comentario.ordem}`, { comentario }, this.props.parameters())
      .then(response => this.setState({ ticket: response.data.ticket, comentarioEditar: { comentario: '' }, modalEditarComentario: !this.state.modalEditarComentario, buttonEditComment : false }, () => {

        const toastProps = {
          text: 'Comentário editado com sucesso',
          actionText: 'Fechar',
          ariaLabel: 'Ticket criado com sucesso, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));

      }))
      .catch(err => this.props.handlingErrorsApi(JSON.stringify(err), this.props));
  }

  async adicionarComentarios({ toast }) {
    let errors = this.state.errors;
    if (this.state.comentario === '' || this.state.comentario.length < 7) { 
      errors.descricaoComentario = true;
      this.setState({errors})
      return false;    
    }else{      
      errors.descricaoComentario = false;
      this.setState({errors})
    }
    let comentario = this.state.comentario;
    await axios.post(`${this.props.getBaseUrlApi()}/comentarios/${this.state.id}`, { comentario }, this.props.parameters())
      .then(response => this.setState({ ticket: response.data.ticket, comentario: '' }, () => {

        const toastProps = {
          text: 'Comentário adicionado com sucesso',
          actionText: 'Fechar',
          ariaLabel: 'Ticket criado com sucesso, clique para fechar'
        };

        toast.show(({ ...toastProps, onActionClick: toast.hide }));

      }))
      .catch(err => {
        this.props.handlingErrorsApi(JSON.stringify(err), this.props)
      });
  }

  onChangeComment(e) {
    this.setState({ comentario: e.target.value });
  }

  toggleModalContestacao(){
    this.setState((prevState) => ({modalContestacao : !prevState.modalContestacao}));
  }

  onChangeMensagemContestacao(e){
    this.setState({mensagemContestacao: e.target.value});
  }

  onClickEnviarContestacao({toast}){
    let errors = this.state.errors;
    let mensagem = this.state.mensagemContestacao;

    if(mensagem === ''){
      errors.mensagemContestacao = true;
    }else{
      errors.mensagemContestacao = false;
    }

    this.setState({errors}, async() => {
      if(!errors.mensagemContestacao){
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/tickets/${this.state.id}/contestar`,
            {mensagem},
            this.props.parameters()
          )
        .then(async response => {  

          const toastProps = {
            text: 'Contestação enviada!',
            actionText: 'Fechar',
            ariaLabel: 'Contestação enviada com sucesso, clique para fechar'
          };
  
          toast.show(({ ...toastProps, onActionClick: toast.hide }));

          this.toggleModalContestacao();    
          await this.getDetailsTicket();
        })
        .catch(err => {
          alert(JSON.stringify(err));
      });
      }
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  selecionaAba(){
    // const parsed = queryString.parse(this.props.location.search);
    // if(parsed.aba !== undefined){
    //   if(parsed.aba !== ''){
    //     this.setState({activeTab: parsed.aba});
    //   }      
    // }
  }

  async baixarAnexo(url) {
    setTimeout(() => {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }, 1000);
  }

  render() {
    return (
      <div id="contentSite" className={className}>
        <div className="Example_container-home">
          <div className="container">
            <div className="Example_box">
              <div className="Example_box-content">
                <Col sm={12}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "resumo" }}
                        onClick={() => {
                          this.toggleTab("resumo");
                        }}
                      >
                        Resumo
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "apontamentos" }}
                        onClick={() => {
                          this.toggleTab("apontamentos");
                        }}
                      >
                        Apontamentos
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "comentarios" }}
                        onClick={() => {
                          this.toggleTab("comentarios");
                        }}
                      >
                        Conversação
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "pendencias" }}
                        onClick={() => {
                          this.toggleTab("pendencias");
                        }}
                      >
                        Pendências
                      </NavLink>
                    </NavItem>
                    {this.state.ticket.tipo === 'Suporte' && <NavItem>
                      <NavLink
                        className={{ active: this.state.activeTab === "contestacao" }}
                        onClick={() => {
                          this.toggleTab("contestacao");
                        }}
                      >
                        Contestação
                      </NavLink>
                    </NavItem>}

                    {this.state.ticket.origemEmissao !== undefined && this.state.ticket.origemEmissao === 'portalCliente' && (
                    <React.Fragment>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === 'anexos' }}
                          onClick={() => { this.toggleTab('anexos'); }}
                        >
                          Anexos
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={{ active: this.state.activeTab === 'logs' }}
                          onClick={() => { this.toggleTab('logs'); }}
                        >
                          Logs
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}

                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="resumo">

                      <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>


                      <div className="row">

                      <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Código: </b>
                            {this.state.ticket.codigo}
                          </div>
                        </div>


                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Data Emissão: </b>
                            {moment(this.state.ticket.dataCriacao).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                        </div>

                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Situação: </b>
                            <span
                            style={{
                              width: "100%",
                              fontWeight: "bold",
                              paddingLeft: "3px"
                            }}
                          >
                            <span
                              className={
                                "tag " +
                                getStatusClassName(this.state.ticket.situacao)
                              }
                            >
                              {getStatusName(this.state.ticket.situacao)}
                            </span>
                          </span>
                          </div>
                        </div>



                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Contato: </b>
                            {this.state.ticket.contato.descricao} (
                            {this.state.ticket.contato.email})
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Telefone: </b>
                            {this.state.ticket.contato.fone}
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipo}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tela/Processo: </b>
                            {this.state.ticket.telaProcesso !== undefined
                              ? this.state.ticket.telaProcesso
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Módulo: </b>
                            {this.state.ticket.modulo !== undefined &&
                            this.state.ticket.modulo !== null
                              ? this.state.ticket.modulo
                              : ""}
                          </div>
                        </div>
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Tipo: </b>
                            {this.state.ticket.tipo}
                          </div>
                        </div> */}
                        {/* <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Prioridade: </b>
                            {this.state.ticket.prioridade}
                          </div>
                        </div> */}
                      </div>


                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Usuário Atendimento: </b>
                            {this.state.ticket.usuario
                              ? this.state.ticket.usuario.descricao
                              : "Não Vinculado"}
                          </div>
                        </div>
                      </div>

                     


<div className="row">
                        <div className="col-md-12 no-padding">
                        <div style={{ padding: "3px" }}>
                            <b>Total de Horas: </b>
                            {this.state.exibeFracao
                            ? this.state.tempoTotal
                            : "Aguarde a finalização do atendimento."}
                          </div>
                      </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Assunto:</b>
                            {this.state.ticket.assunto}
                          </div>
                        </div>
                      </div>


<div className="row">
                        <div className="col-md-12 no-padding">
                          <div style={{ padding: "3px" }}>
                            <b>Descrição:</b>
                            <div
                              className=""
                              dangerouslySetInnerHTML={this.createMarkup(
                                this.state.ticket.descricao
                              )}
                            />
                          </div>
                        </div>
                      </div>


                        
                        {/* <div className="item-detalhe-ticket">
                          <span className="item-detalhe-ticket-span">
                            <b>Código:</b> {this.state.ticket.codigo}{" "}
                          </span>
                          <b>Data Emissão:</b>{" "}
                          {moment(
                            new Date(this.state.ticket.dataCriacao)
                          ).format("DD/MM/YYYY")}
                        </div> */}

                        {/* <div className="item-detalhe-ticket">
                          <span className="item-detalhe-ticket-span">
                            {" "}
                            <b>Tipo:</b> {this.state.ticket.tipo}
                          </span>
                        </div> */}

                        {/* <div className="item-detalhe-ticket">
                          <span className="item-detalhe-ticket-span">
                            <b>Contato:</b>{" "}
                            {this.state.ticket.contato.descricao} (
                            {this.state.ticket.contato.email}){" "}
                          </span>
                        </div> */}

                        {/* <div ref={this.myRef} id="teste" className="item-detalhe-ticket">
                          <b>Situação:</b>
                          <span
                            style={{
                              width: "100%",
                              fontWeight: "bold",
                              paddingLeft: "3px"
                            }}
                          >
                            <span
                              className={
                                "tag " +
                                getStatusClassName(this.state.ticket.situacao)
                              }
                            >
                              {getStatusName(this.state.ticket.situacao)}
                            </span>
                          </span>
                        </div> */}

                        {/* <div className="item-detalhe-ticket">
                          <b>Usuário Atendimento:</b>{" "}
                          {this.state.ticket.usuario
                            ? this.state.ticket.usuario.descricao
                            : "Não Vinculado"}
                        </div>
                        <div className="item-detalhe-ticket">
                          <b>Assunto:</b> {this.state.ticket.assunto}
                        </div>
                        <div className="item-detalhe-ticket">
                          <b>Total de Horas : </b>{" "}
                          {this.state.exibeFracao
                            ? this.state.tempoTotal
                            : "Aguarde a finalização do atendimento."}
                        </div>
                        <div
                          className="item-detalhe-ticket"
                          dangerouslySetInnerHTML={this.createMarkup(
                            "<b>Descrição:</b> <br>" +
                              this.state.ticket.descricao
                          )}
                        /> */}
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            paddingTop: "10px"
                          }}
                        >
                          
                        </div>

                        {this.state.ticket.exibirBotao && 
                          <React.Fragment>
                            {this.state.ticket.permitirContestar ? 
                              (
                                <button 
                                  className="btn btn-danger" 
                                  onClick={this.toggleModalContestacao}
                                >
                                  <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
                                  Contestar
                                </button>

                              ) : (                                
                                <span 
                                  className="btn btn-danger span-btn" 
                                  id="btn-contestar"                                  
                                >
                                  <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
                                  Contestar
                                </span>
                              )
                            }

                            {this.state.ticket.mensagemBloqueio !== '' &&
                              <UncontrolledTooltip placement="right" target='btn-contestar'>
                                {this.state.ticket.mensagemBloqueio}
                              </UncontrolledTooltip>
                            }

                            <Modal isOpen={this.state.modalContestacao} toggle={this.toggleModalContestacao} className="modal-bg">
                              
                              <div className="modal-header modal-footer-bg" toggle={this.toggleModalContestacao} style={{ fontSize: "15px" }}>
                                Contestar Ticket {this.state.ticket.codigo}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Fechar" onClick={this.toggleModalContestacao}><i className="fa fa-times" aria-hidden="true"></i></button>
                              </div>
                              <div 
                                className="modal-body"
                                style={{ overflowY: "scroll", maxHeight: "220px", fontSize: "15px", minHeight: "220px" }}
                              > 
                                <FormGroup>
                                  <Label>Contestação:</Label>
                                  <textarea 
                                    rows={5} 
                                    className={this.state.errors.mensagemContestacao ? "form-control is-invalid" : "form-control"} 
                                    type="text" 
                                    value={this.state.mensagemContestacao} 
                                    onChange={this.onChangeMensagemContestacao} 
                                  />

                                  {this.state.errors.mensagemContestacao  && 
                                    <div className="is-invalid-feedback">
                                      Por favor digite uma mensagem.
                                    </div>
                                  }
                                </FormGroup>
                              </div>
                              <ModalFooter className="modal-footer-bg">

                                <ToastConsumer>
                                  {
                                    ({ show, hide }) => (
                                      <Button 
                                        color="danger" 
                                        onClick={() => this.onClickEnviarContestacao({toast: {show, hide}})}
                                      >
                                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i> Contestar
                                      </Button>
                                    )
                                  }
                                </ToastConsumer>                                

                                <Button color="secondary" onClick={this.toggleModalContestacao}>Cancelar</Button>
                              </ModalFooter>
                            </Modal>   
                          </React.Fragment>
                        }

                      </div>
                    </TabPane>

                    <TabPane tabId="apontamentos">
                      {this.state.load && 
                        <Apontamento
                          apontamentos={this.state.ticket.apontamentos}
                          createMarkup={this.createMarkup}
                          tempoTotal={this.state.tempoTotal}
                          tipoTicket={this.state.ticket.tipo}
                        />
                      }
                    </TabPane>
                    <TabPane tabId="comentarios">

                      <ToastConsumer>
                        {
                          ({ show, hide }) => (
                            <React.Fragment>
                              <CommentList
                                comentarios={this.state.ticket.comentarios}
                                onChangeCommentModalEditar={this.onChangeCommentModalEditar}
                                toggleModalEditarComentario={this.toggleModalEditarComentario}
                                onClickEditComment={this.onClickEditComment}
                                modalEditarComentario={this.state.modalEditarComentario}
                                comentarioEditar={this.state.comentarioEditar}
                                editarComentario={() => this.editarComentario({ toast: { show, hide } })}
                                user={this.state.user}
                                buttonEditComment={this.state.buttonEditComment}
                                errors={this.state.errors}
                              />

                              <AdicionarComentario
                                comentario={this.state.comentario}
                                adicionarComentarios={() => this.adicionarComentarios({ toast: { show, hide } })}
                                onChangeComment={this.onChangeComment}
                                errors={this.state.errors}
                              />
                            </React.Fragment>
                          )
                        }
                      </ToastConsumer>

                    </TabPane>
                    <TabPane tabId="pendencias">
                      {this.state.load &&                  
                        <ListaPendencias
                          pendencias={this.state.pendencias}
                        />           
                      }  
                    </TabPane>
                    <TabPane tabId="contestacao">
                      {this.state.load && 
                        <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>    

                          {this.state.ticket.contestacao === null && 
                            <div 
                            style={{
                              textAlign: "center",
                              padding: "15px"
                            }}
                          >
                            Ticket não possui contestação
                          </div>
                          }   

                          {this.state.ticket.contestacao !== null &&  
                      
                            <React.Fragment>    
                              
                              <div className="item-detalhe-ticket-contestacao">
                                <span className="item-detalhe-ticket-span">
                                  {" "}
                                  <b>Situação:</b>
                                  <span
                                    style={{
                                      width: "100%",
                                      fontWeight: "bold",
                                      paddingLeft: "3px"
                                    }}
                                  >
                                    <span
                                      className={
                                        "tag "+ 
                                        (this.state.ticket.contestacao.aceita === null ? 'analise' : this.state.ticket.contestacao.aceita ? 'aceita': 'recusada')
                                      }
                                    >
                                      {this.state.ticket.contestacao.aceita === null ? 'Análise' : this.state.ticket.contestacao.aceita ? 'Aceita' : 'Recusada'}
                                    </span>
                                  </span> 
                                </span>
                              </div>

                              <div className="item-detalhe-ticket-contestacao">
                                <span className="item-detalhe-ticket-span">
                                  {" "}
                                  <b>Data Emissão:</b> {moment(this.state.ticket.contestacao.data).utcOffset('-03:00').format('DD/MM/YYYY HH:mm')}
                                </span>
                              </div>

                              <div className="item-detalhe-ticket-contestacao" dangerouslySetInnerHTML={this.createMarkup('<b>Contestação:</b> <br>' + this.state.ticket.contestacao.mensagem)} />
                          
                              {this.state.ticket.contestacao.mensagemAnalise !== '' && this.state.ticket.contestacao.mensagemAnalise !== undefined && 
                                 <div className="item-detalhe-ticket-contestacao" dangerouslySetInnerHTML={this.createMarkup('<b>Resposta:</b> <br>' + this.state.ticket.contestacao.mensagemAnalise)} />
                     
                              }
                            </React.Fragment>
                          }                       

                        </div>
                      }
                    </TabPane>


                    {this.state.ticket.anexos !== undefined && (
                   <TabPane tabId="anexos">
                      <div style={{ paddingTop: "15px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                          <th className="text-center">Ações</th>
                        </thead>
                        <tbody>
                          {this.state.ticket.anexos.length > 0 &&
                            this.state.ticket.anexos.map((anexo, key) => (
                              <tr key={key}>
                                <td>{anexo.desc}</td>
                                <td className="text-center">
                                  {moment(anexo.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td className="text-center">
                                  <div style={{ margin: "3px" }}>
                                    <i
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                        color:"#28a745"
                                      }}
                                      className="fa fa-download"
                                      onClick={() => {
                                        this.baixarAnexo(anexo.url)
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                   </div>
                 </TabPane>
                 )}
                 {this.state.ticket.logs !== undefined && (
                   <TabPane tabId="logs">
                      <div style={{ paddingTop: "15px" }}>
                     <table className="table table-bordered table-striped table-hover">
                       <thead className="thead-dark-custom thead-site">
                         <th style={{ borderTop: "none" }}>Descrição</th>
                         <th
                           style={{
                             borderTop: "none",
                             textAlign: "center",
                           }}
                         >
                           Data
                         </th>
                       </thead>
                       <tbody>
                         {this.state.ticket.logs.length > 0 &&
                           this.state.ticket.logs.map((log, key) => (
                             <tr key={key}>
                               <td>{log.desc}</td>
                               <td className="text-center">
                                 {moment(log.dtGravacao).format(
                                   "DD/MM/YYYY HH:mm"
                                 )}
                               </td>
                             </tr>
                           ))}
                       </tbody>
                     </table>
                   </div>
                 </TabPane>
                 )}


                    <Link to={"/tickets"} style={{ color: "inherit", paddingTop: '15px' }}>
                      <i class="fa fa-arrow-left" aria-hidden="true" />{" "}
                      Voltar para listagem
                    </Link>
                  </TabContent>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailTicket;
