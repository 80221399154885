import React, { useState } from "react";
import "./NavBar.css";
import { Link } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

const NavBar = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <nav
      id="navBar-app"
      className={`navbar navbar-expand-lg navbar-light Example_navbar ${className}`}
      style={{ backgroundColor: "#FFF!important" }}
    >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <div className="teste">
        <Link to={'/home'} className="navbar-brand Example_navbar-brand">
          <img src="/images/logo-small.png" className="navbar-logo"/> SAC Portal
        </Link>
        <div className="">
          <div className="teste1"></div>
          <div className="teste1"></div>
          <div className="teste1"></div>
        </div>
      </div> */}
      <div
        className="collapse navbar-collapse"
        id="navbarTogglerDemo01"
        style={{ minHeight: "70px", backgroundColor: "#FFF!important" }}
      >
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 Example_navbar-itens-acesso">
          <li className="nav-item Example_nav-item">
            <Link
              to={"#"}
              style={{ textDecoration: "none" }}
              className="nav-link Example_nav-link"
            >
              <i
                className={`fa ${props.icone} navbar-item-active`}
                aria-hidden="true"
                style={{ fontSize: "25px" }}
              ></i>
              <span className={"navbar-item-active"}>{props.titulo}</span>
              <span className="sr-only">(current)</span>
            </Link>
          </li>
        </ul>
        <div className="form-inline my-2 my-lg-0">
          <a className="nav-link">
            <img
              src="/images/admin.png"
              alt="profile"
              style={{ width: "30px" }}
            />
            <span className="nav-profile-name">
              Admin
              <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className="botao-drop"
                direction="left"
              >
                <DropdownToggle>
                  <i
                    class="fa fa-chevron-down font-size-14"
                    aria-hidden="true"
                  ></i>
                </DropdownToggle>
                <DropdownMenu>
                  <Link to={`/notificacoes`} style={{ color: "inherit" }}>
                    <DropdownItem>Notificações</DropdownItem>
                  </Link>

                  <DropdownItem divider />

                  <Link to={`/logout`} style={{ color: "inherit" }}>
                    <DropdownItem>Sair</DropdownItem>
                  </Link>
                </DropdownMenu>
              </ButtonDropdown>
            </span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
