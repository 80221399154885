import React, { Fragment } from 'react';
import { Button, FormGroup, Label, Input } from 'reactstrap';

const AdicionarComentario = ({ comentario, adicionarComentarios, onChangeComment, errors }) => {

  return (
    <Fragment>      
      <div className="form-group" style={{width: "100%"}}>
        <Label>Comentário:</Label>
        <Input type="textarea" name="text" id="exampleText" className={errors.descricaoComentario ? 'is-invalid' : ''} value={comentario} onChange={onChangeComment} />
        {errors.descricaoComentario && 
          <div className="is-invalid-feedback">
            Por favor digite um comentário com no mínimo 7 caracteres.
          </div>
        }
      </div>
      <FormGroup>
        <Button 
          type="button" 
          color="success" 
          onClick={adicionarComentarios}
        >
          <i className="fa fa-floppy-o" aria-hidden="true"></i>{' '}
          Salvar
        </Button>
      </FormGroup>
    </Fragment>
  );
}

export default AdicionarComentario;