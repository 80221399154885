import axios from 'axios';
require('dotenv').config();

let baseURL = '';

if (window.location.href.search('sac.priorize') !== -1) {
  baseURL = process.env.REACT_APP_DEV_API_URL;
} else {
  baseURL = process.env.REACT_APP_DEV_API_URL_LOCAL;
}

const api = axios.create({
  baseURL: baseURL,
})

export default api;