import React, { Component } from "react";
import api from "../../services/api";
import validator from "validator";
import { Button } from "reactstrap";
import "./RequisitoNovo.css";
import axios from "axios";
import Select from "react-select";
import { ToastConsumer } from "react-awesome-toasts";

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

class RequisitoNovo extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputContact = this.onChangeInputContact.bind(this);
    this.loadData = this.loadData.bind(this);
    this.validar = this.validar.bind(this);
    this.loadContatos = this.loadContatos.bind(this);

    this.state = {
      contato: null,
      titulo: "",
      descricao: "",
      prioridade: "Baixa",
      contatos: [],
      errors: {
        titulo: false,
        descricao: false,
        contato: false,
      },
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadContatos();
  }

  async loadContatos() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/contatos`, this.props.parameters())
      .then((response) => {
        let contatos = response.data.contatos;
        contatos = contatos.map((contato) => {
          contato.value = contato.descricao;
          contato.label = contato.descricao;
          return contato;
        });
        this.setState({ contatos });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  onChangeInputContact(contact) {
    this.setState({
      contato: contact,
    });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async validar() {
    let isValid = true;
    let errors = this.state.errors;

    if (validator.isEmpty(this.state.titulo, [{ ignore_whitespace: true }])) {
      errors.titulo = true;
      isValid = false;
    } else {
      errors.titulo = false;
    }
    if (
      validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])
    ) {
      errors.descricao = true;
      isValid = false;
    } else {
      errors.descricao = false;
    }

    if (this.state.contato === null) {
      isValid = false;
      errors.contato = true;
    } else {
      errors.contato = false;
    }

    await this.setState({ errors });
    return isValid;
  }

  async onSubmit({ toast }, permanecerPagina, manterDados = false) {
    const toastProps = {
      text: "Requisito criado com sucesso",
      actionText: "Fechar",
      ariaLabel: "Requisito criado com sucesso, clique para fechar",
    };

    let isValid = await this.validar();
    if (!isValid) return false;

    let localContato = Object.assign({}, this.state.contato);
    delete localContato.setor;
    delete localContato.emailMarketing;
    delete localContato.isPrincipal;
    delete localContato.usaFranquia;
    delete localContato.value;
    delete localContato.label;

    let prioridade = this.state.prioridade;
    if (prioridade === "Alta") {
      prioridade = 0;
    }
    if (prioridade === "Média") {
      prioridade = 1;
    }
    if (prioridade === "Baixa") {
      prioridade = 2;
    }

    const objectToSend = {
      contato: localContato,
      titulo: this.state.titulo,
      descricao: this.state.descricao,
      prioridade: prioridade,
    };

    await api
      .post("requisitos", { requisito: objectToSend }, this.props.parameters())
      .then((res) => {
        toast.show({ ...toastProps, onActionClick: toast.hide });

        if (permanecerPagina) {

          if(!manterDados){
            this.setState({
              titulo: "",
              descricao: "",
              prioridade: "Baixa",
              contato: null,
              errors: {
                titulo: false,
                descricao: false,
                contato: false,
              },
            });
          }else{
            this.setState({
              titulo: "",
              descricao: "",
              errors: {
                titulo: false,
                descricao: false,
                contato: false,
              },
            });
          }
          
        } else {
          setTimeout(() => {
            this.props.history.push("/requisitos");
          }, 1000);
        }
      });
  }

  render() {
    return (
      <div>
        <div id="contentSite" className={className}>
          <div className="container">
            <div className="Example_box">
              <div className="Example_box-content">
                <div
                  className="offset-sm-1 col-sm-9"
                  style={{ paddingTop: "15px" }}
                >
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress" className="label-form">
                          Contato
                        </label>
                        {this.state.contatos.length ? (
                          <React.Fragment>
                            <Select
                              value={this.state.contato}
                              onChange={this.onChangeInputContact}
                              options={this.state.contatos}
                              className={
                                this.state.errors.contato ? "is-invalid" : ""
                              }
                            />

                            {this.state.errors.contato && (
                              <div className="is-invalid-feedback">
                                Por favor selecione um contato.
                              </div>
                            )}
                          </React.Fragment>
                        ) : (
                          <select
                            className="form-control"
                            id="contato"
                            disabled={true}
                          />
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress2" className="label-form">
                          Prioridade
                        </label>
                        <select
                          className="form-control"
                          id="prioridade"
                          value={this.state.prioridade}
                          onChange={this.onChangeInput}
                        >
                          <option value="Baixa">Baixa</option>
                          <option value="Média">Média</option>
                          <option value="Alta">Alta</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputCity" className="label-form">
                          Título
                        </label>
                        <input
                          type="text"
                          className={
                            this.state.errors.titulo
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          id="titulo"
                          name="titulo"
                          onChange={this.onChangeInput}
                          value={this.state.titulo}
                        />
                        {this.state.errors.titulo && (
                          <div className="is-invalid-feedback">
                            Por favor digite um título.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputCity" className="label-form">
                          Descrição
                        </label>
                        <small style={{ display: "block", color: "#656363" }}>
                        Descreva detalhes que nos ajudem a entender a sua necessidade. Isso agilizará a análise do seu requisito.
                        </small>
                        <textarea
                          id="descricao"
                          value={this.state.descricao}
                          onChange={this.onChangeInput}
                          rows={4}
                          className={
                            this.state.errors.descricao
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                        {this.state.errors.descricao && (
                          <div className="is-invalid-feedback">
                            Por favor digite uma descrição.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="div-center">
                      <ToastConsumer>
                        {({ show, hide }) => (
                          <React.Fragment>
                            <Button
                              type="button"
                              className="button-novo-requisito"
                              style={{ margin: "5px" }}
                              color="success"
                              onClick={() =>
                                this.onSubmit({ toast: { show, hide } }, false)
                              }
                            >
                              <i
                                className="fa fa-floppy-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Salvar
                            </Button>
                            <Button
                              type="button"
                              className="button-novo-requisito"
                              style={{ margin: "5px" }}
                              color="success"
                              onClick={() =>
                                this.onSubmit({ toast: { show, hide } }, true)
                              }
                            >
                              <i
                                className="fa fa-floppy-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Salvar/Novo
                            </Button>

                            <Button
                              type="button"
                              className="button-novo-requisito"
                              style={{ margin: "5px" }}
                              color="success"
                              onClick={() =>
                                this.onSubmit({ toast: { show, hide } }, true, true)
                              }
                            >
                              <i
                                className="fa fa-floppy-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Salvar/Similar
                            </Button>
                          </React.Fragment>
                        )}
                      </ToastConsumer>

                      <Button
                        type="button"
                        className="button-novo-requisito"
                        style={{ margin: "5px" }}
                        onClick={() => this.props.history.push("/requisitos")}
                      >
                        <i
                          className="fa fa-arrow-circle-o-left"
                          aria-hidden="true"
                        ></i>{" "}
                        Voltar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequisitoNovo;
