import React, { Component } from 'react';
import axios from 'axios';
import '../../login.css';

class Login extends Component {
  constructor() {
    super();
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      cnpj: '',
      login: '',
      password: '',
      error: false
    }
  }

  async componentDidMount() {
    let ultimoLoginStr = localStorage.getItem('ultimoLogin');

    if(ultimoLoginStr !== null && ultimoLoginStr !== ''){
      let ultimoLogin = JSON.parse(ultimoLoginStr);
      this.setState({cnpj : ultimoLogin.cnpj, login: ultimoLogin.login})
    }
  }

  handleChangeInput(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [e.target.id]: value
    });
  }

  async handleSubmit(e) {
    let localLogin = this.state.login;
    let toSend = {
      cnpj : this.state.cnpj,
      login: localLogin,
      password: this.state.password
    };

    await axios.post(`${this.props.getBaseUrlApi()}/login`, toSend).then(async (res) => {//TODO: AXIOS
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user', JSON.stringify(res.data.user));
      delete toSend.password;
      localStorage.setItem('ultimoLogin', JSON.stringify(toSend));
      this.props.history.push('/home');      

    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        alert(error.response.data.erro);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  }
 
  render() {
    return (
      <React.Fragment>
        <div id="login_container" style={ {   backgroundImage: `url("./images/inicial.jpg")`,   }}>
          <div className="filter" style={{ "position": "absolute", "backgroundColor": "black", "width": "100%", "height": "100%", "opacity": "0.8" }}></div>          
          <div className="container">
            <div className="card card-container background-card" style={{backgroundColor: "transparent"}}>
              <center>
            	  <img className="img-responsive" src="./images/logo.png" width="250" alt="logo"/>
              </center>
              <p id="profile-name" className="profile-name-card"></p>
              <form className="form-signin" onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit();
              }}>
                <input type="text" id="cnpj" className="form-control" placeholder="CNPJ" onChange={this.handleChangeInput} value={this.state.cnpj} required autoFocus style={{backgroundColor: "transparent"}}/>               
                <input type="text" id="login" className="form-control" placeholder="LOGIN" onChange={this.handleChangeInput} value={this.state.login} required style={{backgroundColor: "transparent"}} />
                <input type="password" id="password" className="form-control" placeholder="SENHA" onChange={this.handleChangeInput} value={this.state.password} style={{backgroundColor: "transparent"}} />
                <button 
                  className="btn btn-lg btn-primary btn-block btn-signin" 
                  style={{"backgroundColor" : "#d40e0e!important", "borderRadius": "50px", 
                    "backgroundImage": "linear-gradient(to right, #fecd00, #fbba00, #f59d00)", 
                    "cursor": "pointer", "height": "50px", color:'#333'}}
                  type="submit"
                >ACESSAR</button>
              </form>            
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;