import React, { Component } from "react";
import Switch from "react-input-switch";
import axios from "axios";
import moment from "moment";

const className = localStorage.getItem('menuCompacto') !== null ? localStorage.getItem('menuCompacto') === 'true' ? 'contentSite-menu-compacto' : '' : '';

class Cobranca extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadCliente = this.loadCliente.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.loadCobrancas = this.loadCobrancas.bind(this);
    this.onChangeCobranca = this.onChangeCobranca.bind(this);

    this.state = {
      cliente: { contatos: [] },
      cobrancas: [],
      cobrancaSelecionada: null,
      itens: [],
      relatorio: null,
      exibirLoading: false,
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadCliente();
    await this.loadCobrancas();
  }

  async loadCobrancas() {
    //TODO: AXIOS
    await axios
      .get(`${this.props.getBaseUrlApi()}/cobrancas`, this.props.parameters())
      .then((response) => {
        let cobrancas = response.data.cobrancas;

        this.setState({ cobrancas });
      });
  }

  onChangeCobranca(e) {
    this.setState(
      {
        cobrancaSelecionada: e.target.value,
        itens: [],
        relatorio: null,
        exibirLoading: true,
      },
      async () => {
        await axios
          .get(
            `${this.props.getBaseUrlApi()}/cobrancas/${
              this.state.cobrancaSelecionada
            }`,
            this.props.parameters()
          )
          .then((response) => {
            let itens = response.data.itens;
            let relatorio = null;

            if (itens.length) {
              let item = itens[0].estorias[0].apontamentos[0];

              relatorio = {
                cliente: item.pessoa,
                periodo:
                  moment(item.dtexercicioIni).format("DD/MM/YYYY") +
                  " à " +
                  moment(item.dtexercicioFim).format("DD/MM/YYYY"),
                emissao: moment(item.dataEmissao).format("DD/MM/YYYY"),
              };
            }

            this.setState({ itens, relatorio, exibirLoading: false });
          });
      }
    );
  }

  async loadCliente() {
    //TODO: AXIOS
    await axios
      .get(`${this.props.getBaseUrlApi()}/admin`, this.props.parameters())
      .then((response) => {
        let cliente = response.data.cliente;

        for (let contato of cliente.contatos) {
          if (!contato.usaFranquia) {
            contato.usaFranquia = false;
          }
        }

        this.setState({ cliente });
      });
  }

  async handleSwitch(e, id) {
    let localCliente = this.state.cliente;

    for (let contato of localCliente.contatos) {
      if (contato.id === id) {
        contato.usaFranquia = e === 1 ? true : false;
      }
    }

    const object = {
      id,
      usaFranquia: e === 1 ? true : false,
    };
    //TODO: AXIOS
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/admin/atualiza-franquia`,
        { cliente: object },
        this.props.parameters()
      )
      .then((response) => {});

    this.setState({ cliente: localCliente });
  }

  render() {
    return (
      <div id="contentSite" className={className}>
        <div className="container">
          <div
            style={{
              float: "left",
              width: "100%",
              padding: "0 20px",
              backgroundColor: "#FFF",
            }}
          >
            <div
              className="row row-cobrancas"
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                backgroundColor: "#f2f3f8",
                padding: "10px 0",
              }}
            >
              <div className="form-group col-md-4 group-tickets noprint">
                <label
                  for="inputCity"
                  className="label-form"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Período
                </label>
                <select
                  className="form-control input-sm input-filter"
                  value={this.state.cobrancaSelecionada}
                  onChange={(e) => this.onChangeCobranca(e)}
                >
                  <option value="">Selecionar</option>
                  {this.state.cobrancas.length &&
                    this.state.cobrancas.map((cobranca) => (
                      <option value={cobranca.idCobranca}>
                        {moment(cobranca.dtexercicioIni).format("DD/MM/YYYY") +
                          " à " +
                          moment(cobranca.dtexercicioFim).format("DD/MM/YYYY")}
                      </option>
                    ))}
                </select>
              </div>

              <div
                className="form-group col-md-2 noprint"
                style={{ marginTop: "22px", padding: "0" }}
              >
                <button
                  className="btn btn-info btn-sm noprint"
                  id="btn-imprimir-tickets"
                  style={{
                    padding: ".275rem .55rem",
                    margin: "10px 5px 0px 5px",
                  }}
                  onClick={() => window.print()}
                >
                  <i className="fa fa-print" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            {this.state.exibirLoading && (
              <div className="container-loading">
                <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                  <div class="ldio-jz4kbaf040e">
                    <div></div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.cobrancaSelecionada !== null && (
              <React.Fragment>
                {this.state.relatorio !== null && (
                  <React.Fragment>
                    <div
                      style={{
                        float: "left",
                      }}
                    >
                      <img
                        className="img-responsive"
                        src="./images/logo-black.png"
                        width="210"
                        alt="logo"
                      />
                    </div>
                    <div
                      style={{
                        float: "left",
                        padding: "10px 20px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        Cliente: {this.state.relatorio.cliente}
                      </div>

                      <div
                        style={{
                          float: "left",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        <div>Período: {this.state.relatorio.periodo}</div>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          Data Fechamento: {this.state.relatorio.emissao}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <table
                  style={{
                    fontSize: "12px",
                    width: "100%",
                  }}
                >
                  {this.state.itens.length > 0 &&
                    this.state.itens.map((item) => (
                      <React.Fragment>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              padding: "3px 0px",
                              fontSize: "13px",
                              paddingTop: "20px",
                            }}
                          >
                            {item.estorias[0].apontamentos[0].tpEstoria}
                          </td>

                          <td
                            style={{
                              fontWeight: "bold",
                              padding: "3px 0px",
                            }}
                          >
                            {" "}
                            Solicitante
                          </td>
                        </tr>

                        {item.estorias.map((estoria) => (
                          <React.Fragment>
                            <tr>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  fontWeight: "bold",
                                  padding: "3px 0px",
                                  paddingTop: "8px",
                                }}
                              >
                                <div
                                  style={{
                                    boxSizing: "border-box",
                                    display: "inline-block",
                                    maxWidth: "650px",
                                  }}
                                >
                                  {estoria.apontamentos[0].estoria}
                                </div>
                              </td>
                              <td
                                style={{
                                  padding: "3px 0px",
                                }}
                              >
                                {estoria.apontamentos[0].dscontato}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                Técnico
                              </td>
                            </tr>

                            {estoria.apontamentos.map((apontamento) => (
                              <React.Fragment>
                                <tr>
                                  <td
                                    colSpan={2}
                                    style={{
                                      padding: "0px 20px",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        maxWidth: "650px",
                                      }}
                                    >
                                      {apontamento.obs.obs}
                                    </div>
                                  </td>
                                  <td>{apontamento.login}</td>
                                  <td>{apontamento.tempoCobrado}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                            <tr>
                              <td
                                colSpan={4}
                                style={{
                                  height: "5px",
                                }}
                              >
                                <hr
                                  size="1"
                                  style={{
                                    border: "1px dashed #ddd",
                                    marginTop: "0",
                                    marginBottom: "0",
                                  }}
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <tr>
                          <td
                            colSpan={3}
                            style={{
                              padding: "0px 20px",
                              textAlign: "right",
                              padding: "10px",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Tempo Total:{" "}
                            {item.estorias[0].apontamentos[0].tpEstoria}
                          </td>
                          <td>{item.tempoTotal}</td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <div
                              style={{
                                width: "100%",
                                height: "1px",
                                backgroundColor: "#333",
                              }}
                            ></div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </table>

                {this.state.itens.length > 0 && (
                  <div style={{ float: "right", paddingTop: "40px" }}>
                    <table
                      style={{
                        fontSize: "14px",
                        width: "100%",
                        fontWeight: "bold",
                        marginBottom: "200px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Tipo de Estória</th>
                          <th>Tempo Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.itens.map((item) => (
                          <tr>
                            <td
                              style={{
                                boxSizing: "border-box",
                                display: "inline-block",
                                maxWidth: "500px",
                                width: "500px",
                              }}
                            >
                              {item.descricao}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {item.tempoTotal}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Cobranca;
