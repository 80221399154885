import React, { Component } from "react";
import Switch from "react-input-switch";
import axios from "axios";
import Pagination from "react-js-pagination";

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadCliente = this.loadCliente.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.paginar = this.paginar.bind(this);

    this.state = {
      cliente: { contatos: [] },
      contatosBkp: [],
      contatosPaginados: [],
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadCliente();
  }

  async loadCliente() {
    //TODO: AXIOS
    await axios
      .get(`${this.props.getBaseUrlApi()}/admin`, this.props.parameters())
      .then((response) => {
        let cliente = response.data.cliente;

        for (let contato of cliente.contatos) {
          if (!contato.usaFranquia) {
            contato.usaFranquia = false;
          }
        }

        this.setState({ cliente, contatosBkp: cliente.contatos }, () =>
          this.paginar(1)
        );
      });
  }

  paginar(pagina = 1) {
    const LIMIT = this.state.paginacao.limite;
    let contatos = this.state.contatosBkp.concat();
    pagina = pagina - 1;
    contatos = contatos.slice(pagina * LIMIT, (pagina + 1) * LIMIT);
    pagina = pagina + 1;

    let paginacao = this.state.paginacao;
    paginacao.pagina = pagina;
    paginacao.totalItens = this.state.contatosBkp.length;
    this.setState({ contatosPaginados: contatos, paginacao });
  }

  async handleSwitch(e, id) {
    let localCliente = this.state.cliente;

    for (let contato of localCliente.contatos) {
      if (contato.id === id) {
        contato.usaFranquia = e === 1 ? true : false;
      }
    }

    const object = {
      id,
      usaFranquia: e === 1 ? true : false,
    };
    //TODO: AXIOS
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/admin/atualiza-franquia`,
        { cliente: object },
        this.props.parameters()
      )
      .then((response) => {});

    this.setState({ cliente: localCliente });
  }

  render() {
    return (
      <div id="contentSite" className={className}>
        <div className="">
          <div className="container">
            <div className="Example_box">
              <div
                style={{ padding: "15px 30px", fontSize: "14px" }}
                className="font-tickets"
              >
                <div
                  className="row row-lista-tickets"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                ></div>

                <React.Fragment>
                  <table
                    className="table table-hover table-striped"
                    style={{ fontSize: "15px" }}
                  >
                    <thead className="thead-dark-custom">
                      <tr>
                        <th style={{}}>Nome</th>
                        <th style={{}}>Setor</th>
                        <th>Email</th>
                        <th>Franquia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.contatosPaginados.map((contato, key) => (
                        <tr key={key}>
                          <td>{contato.descricao}</td>
                          <td>{contato.setor}</td>
                          <td>{contato.email}</td>

                          <td>
                            {" "}
                            <Switch
                              value={contato.usaFranquia ? 1 : 0}
                              onChange={(e) => this.handleSwitch(e, contato.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.paginacao.totalItens > 0 && (
                      <div className="paginator">
                        <Pagination
                          prevPageText={<i className="fa fa-angle-left" />}
                          nextPageText={<i className="fa fa-angle-right" />}
                          firstPageText={
                            <i className="fa fa-angle-double-left" />
                          }
                          lastPageText={
                            <i className="fa fa-angle-double-right" />
                          }
                          activePage={this.state.paginacao.pagina}
                          itemsCountPerPage={this.state.paginacao.limite}
                          totalItemsCount={this.state.paginacao.totalItens}
                          onChange={(pagina) => this.paginar(pagina)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
