import React, { Fragment } from "react";
import { Table } from "reactstrap";
import moment from "moment";

const ListaApontamentos = props => {
  const formatDate = data => {
    return moment(new Date(data))
      .format("DD/MM/YYYY")
      .toString();
  };

  const formatDateTime = data => {
    return moment(new Date(data))
      .format("HH:mm") 
      .toString();
  };

  const getTempoTotal = () => {
    let apontamentos = props.apontamentos;
    let totalMinutos = 0;
    let totalHoras = 0;

    for(let apontamento of apontamentos){
      totalMinutos += apontamento.tempoTotal.minutos;
    }

    if(totalMinutos > 59){
      totalHoras = parseInt(totalMinutos / 60);
      totalMinutos = totalMinutos - (totalHoras * 60);
    }
    return `${totalHoras.toString().padStart(2, '0')}:${totalMinutos.toString().padStart(2, '0')}:00`;
  }

  return (
    <Fragment>
      <Table striped hover bordered className="col-sm-6" style={{marginTop:"15px", paddingBottom: '40px'}}>
        <thead>
          <tr>
            <th>Data</th>
            <th style={{minWidth: '115px'}}>Hora Inicial</th>
            {props.tipoTicket !== 'Suporte' && <th style={{minWidth: '115px'}}>Hora Final</th>}
            <th>Atendente</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody>
          {props.apontamentos.map((apontamento, index) => (
            <tr
              key={index}
              style={{ color: "#FFF!important", cursor: "pointer" }}
              onClick={() => props.alteraDescricao(apontamento.descricao)}
            >
              <td>{formatDate(apontamento.horaInicial)}</td>
              <td>{formatDateTime(apontamento.horaInicial)}</td>
              {props.tipoTicket !== 'Suporte' ? (apontamento.horaFinal !== '' ?  (<td>{formatDateTime(apontamento.horaFinal)}</td>) : (<td> - </td>)): '' }

              <td>
              {apontamento.usuarioCriacao.descricao.indexOf(" ") !== -1 ? apontamento.usuarioCriacao.descricao.substring(0, apontamento.usuarioCriacao.descricao.indexOf(" ")) : apontamento.usuarioCriacao.descricao}
              </td>
              <td style={{minWidth: "552px"}}>
              <div style={{maxHeight: "250px", overflow: "auto"}} dangerouslySetInnerHTML={props.createMarkup(apontamento.descricao.replace(/(?:\r\n|\r|\n)/g,"<br />"))} />
             </td>
            </tr>
          ))}
          {props.tipoTicket !== 'Suporte' &&
            <tr>
              <td colSpan={5}>
              Tempo Total: {getTempoTotal()}
              </td>
            </tr>
          }
          
        </tbody>
      </Table>
    </Fragment>
  );
};

export default ListaApontamentos;
