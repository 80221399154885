import React, { Component } from "react";
import { Button, UncontrolledTooltip, Modal, ModalFooter } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "react-js-pagination";
import "./Ticket.css";
import { getStatusClassNamePreTicket } from "../../utils";

const versaoFiltro = "filtroPortal2";
const className = localStorage.getItem('menuCompacto') !== null ? localStorage.getItem('menuCompacto') === 'true' ? 'contentSite-menu-compacto' : '' : '';

class Ticket extends Component {
  constructor(props) {
    super(props);
    this.salvarFiltro = this.salvarFiltro.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.ordenar = this.ordenar.bind(this);
    this.handleInputFiltroChange = this.handleInputFiltroChange.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.actionFiltrar = this.actionFiltrar.bind(this);
    this.getTickets = this.getTickets.bind(this);
    this.paginar = this.paginar.bind(this);

    this.state = {
      tickets: [],
      ticketsBkp: [],
      ticketsPaginados: [],
      filtro: null,
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      },
      exibirLoading: false,
      exibirMensagemTickets: false,
    };
  }

  async componentDidMount() {
    let filtroStr = localStorage.getItem(versaoFiltro);
    let filtro = {};

    if (filtroStr === null) {
      filtro = {
        pesquisa: {
          codigo: "",
          situacao: "",
          tipo: "",
          periodo: 0,
        },
        ordenacao: {
          coluna: "codigo", // ['codigo', 'usuario', 'dataEmissao', 'situacao', 'tipo']
          tipo: "ASC",
        },
      };

      localStorage.setItem(versaoFiltro, JSON.stringify(filtro));
    } else {
      filtro = JSON.parse(filtroStr);
    }

    this.setState({ filtro }, async () => await this.getTickets());
  }

  async getTickets() {
    // const encodeQueryData = (data) => {
    //   const ret = [];
    //   for (let d in data)
    //     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    //     return ret.join('&');
    // }

    // let filtro = encodeQueryData(this.state.filtro.pesquisa)

    this.setState({ exibirLoading: true });

    await axios
      .get(`${this.props.getBaseUrlApi()}/tickets`, this.props.parameters())
      .then((response) => {
        let tickets = response.data.tickets;
        for (let i = 0; i < tickets.length; i++) {
          tickets[i].situacaoInt = tickets[i].situacao.toString();

          if (!tickets[i].hasOwnProperty("dataFinalizacao")) {
            tickets[i].dataFinalizacao = null;
            tickets[i].dataFinalizacaoString = " - ";
          }

          if (tickets[i].situacao === 0) {
            tickets[i].situacao = "Pendente";
            tickets[i].situacaoClass = "aFazer";
          }
          if (tickets[i].situacao === 1) {
            tickets[i].situacao = "Agendado";
            tickets[i].situacaoClass = "agendado";
          }
          if (tickets[i].situacao === 2) {
            tickets[i].situacao = "Atendendo";
            tickets[i].situacaoClass = "atendendo";
          }
          if (tickets[i].situacao === 3) {
            tickets[i].situacao = "Parado";
            tickets[i].situacaoClass = "parado";
          }
          if (tickets[i].situacao === 4) {
            tickets[i].situacao = "Finalizado";
            tickets[i].situacaoClass = "finalizado";
          }
          if (tickets[i].situacao === 6) {
            tickets[i].situacao = "Em Análise";
            tickets[i].situacaoClass = "emAnalise";
          }

          if(tickets[i].type === "pre-ticket"){
            tickets[i].situacao = tickets[i].situacaoPortalCliente;
            tickets[i].situacaoClass =  getStatusClassNamePreTicket(
              tickets[i].situacaoPortalCliente
            );
          }

          tickets[i].usuarioDescricao = tickets[i].usuario
            ? tickets[i].usuario.descricao.split(" ")[0]
            : "NÃO VINCULADO";

          if (tickets[i].assunto.length > 100) {
            tickets[i].assuntoResumido =
              tickets[i].assunto.substr(0, 100) + "...";
          } else {
            tickets[i].assuntoResumido = tickets[i].assunto;
          }

          if (tickets[i].dataFinalizacao !== null) {
            tickets[i].dataFinalizacao = new Date(tickets[i].dataFinalizacao);
            tickets[i].dataFinalizacaoString = new Date(
              tickets[i].dataFinalizacao
            ).toLocaleDateString();
          }

          tickets[i].dataCriacao = new Date(tickets[i].dataCriacao);
        }

        this.setState(
          { tickets, ticketsBkp: tickets, ticketsPaginados: tickets },
          () => {
            this.actionFiltrar();
            this.setState({ exibirLoading: false });
            if (tickets.length === 0) {
              this.setState({ exibirMensagemTickets: true });
            }
          }
        );
      })
      .catch((err) => {
        console.log("rrurur", err);
        this.props.handlingErrorsApi(JSON.stringify(err), this.props);
      });
  }

  salvarFiltro(filtro, cb = null) {
    localStorage.setItem(versaoFiltro, JSON.stringify(filtro));
    this.setState({ filtro }, () => {
      if (cb !== null) cb();
    });
  }

  createMarkup(html) {
    return { __html: html };
  }

  ordenar(coluna) {
    if (this.state.filtro !== null) {
      let filtro = this.state.filtro;

      if (filtro.ordenacao.coluna === coluna) {
        if (filtro.ordenacao.tipo === "ASC") {
          filtro.ordenacao.tipo = "DESC";
        } else {
          filtro.ordenacao.tipo = "ASC";
        }
      } else {
        filtro.ordenacao.coluna = coluna;
        filtro.ordenacao.tipo = "ASC";
      }

      let tickets = this.state.ticketsPaginados;

      if (filtro.ordenacao.tipo === "ASC") {
        if (coluna === "dataEmissao") {
          tickets = tickets.sort(function (a, b) {
            return a.dataEmissao.getTime() - b.dataEmissao.getTime();
          });
        } else {
          tickets.sort(function (a, b) {
            return a[coluna] > b[coluna] ? 1 : b[coluna] > a[coluna] ? -1 : 0;
          });
        }
      } else if (filtro.ordenacao.tipo === "DESC") {
        if (coluna === "dataEmissao") {
          tickets = tickets.sort(function (a, b) {
            return b.dataEmissao.getTime() - a.dataEmissao.getTime();
          });
        } else {
          tickets.sort(function (a, b) {
            return a[coluna] < b[coluna] ? 1 : b[coluna] < a[coluna] ? -1 : 0;
          });
        }
      }

      this.setState({ ticketsPaginados: tickets });
      this.salvarFiltro(filtro);
    }
  }

  handleInputFiltroChange(e, tipo) {
    let value = e.target.value;
    let filtro = this.state.filtro;
    filtro.pesquisa[tipo] =
      tipo === "codigo" ? value.toString().toUpperCase() : value;
    this.setState({ filtro });
  }

  limparFiltro() {
    let filtro = this.state.filtro;
    filtro.pesquisa.codigo = "";
    filtro.pesquisa.situacao = "";
    filtro.pesquisa.tipo = "";
    filtro.pesquisa.periodo = 0;
    this.setState({ filtro }, () => this.actionFiltrar());
  }

  async actionFiltrar() {
    let filtro = this.state.filtro;
    let tickets = this.state.ticketsBkp.concat();

    if (filtro.pesquisa.codigo !== "") {
      tickets = tickets.filter((ticket) => {
        let ticketComparer = Object.assign({}, ticket);
        let codigo = filtro.pesquisa.codigo;
        if (ticketComparer.codigo.includes(codigo)) {
          return ticket;
        }
      });
    }

    if (filtro.pesquisa.tipo !== "") {
      tickets = tickets.filter(
        (ticket) => ticket.tipo === filtro.pesquisa.tipo
      );
    }

    if (filtro.pesquisa.situacao !== "") {
      tickets = tickets.filter((ticket) => {
        if (ticket.situacaoInt === filtro.pesquisa.situacao) return ticket;
      });
    }

    let dataInicial = "";

    if (
      filtro.pesquisa.periodo !== "" &&
      (filtro.pesquisa.periodo !== "null") & (filtro.pesquisa.periodo !== null)
    ) {
      if (filtro.pesquisa.periodo == 0) {
        // MES ATUAL

        let date = new Date();
        dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

        tickets = tickets.filter((ticket) => {
          if (ticket.dataFinalizacao !== null) {
            if (moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          } else {
            if (moment(ticket.dataCriacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          }
        });
      } else if (filtro.pesquisa.periodo == 1) {
        // MES PASSADO
        // dataInicial = moment().subtract(1, "months").format("YYYY-MM") + "-01";
        let date = new Date();
        dataInicial = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        const lastday = function (y, m) {
          return new Date(y, m + 1, 0);
        };

        let dataFinal = lastday(
          dataInicial.getFullYear(),
          dataInicial.getMonth()
        );

        tickets = tickets.filter((ticket) => {
          if (ticket.situacao === "Finalizado") {
            if (
              moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial) &&
              moment(ticket.dataFinalizacao).isSameOrBefore(dataFinal)
            ) {
              return ticket;
            }
          }
        });
      } else if (filtro.pesquisa.periodo == 2) {
        // ULTIMOS 30 DIAS
        dataInicial = moment().subtract(30, "days").format("YYYY-MM-DD");

        tickets = tickets.filter((ticket) => {
          if (ticket.dataFinalizacao !== null) {
            if (moment(ticket.dataFinalizacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          } else {
            if (moment(ticket.dataCriacao).isSameOrAfter(dataInicial)) {
              return ticket;
            }
          }
        });
      }
    }

    let ticketsAberto = tickets.filter(
      (ticket) => ticket.dataFinalizacao === null
    );
    let ticketsFinalizados = tickets.filter(
      (ticket) => ticket.dataFinalizacao !== null
    );
    let ticketsOrdenados = [].concat(ticketsAberto);
    ticketsFinalizados = ticketsFinalizados.sort(function (a, b) {
      return a.dataFinalizacao.getTime() - b.dataFinalizacao.getTime();
    });
    ticketsOrdenados = ticketsOrdenados.concat(ticketsFinalizados);

    let exibirMensagemTickets = ticketsOrdenados.length === 0 ? true : false;

    this.setState({ tickets: ticketsOrdenados, exibirMensagemTickets }, () => {
      this.salvarFiltro(filtro, () => this.paginar(1));
    });
  }

  paginar(pagina = 1) {
    const LIMIT = this.state.paginacao.limite;
    let tickets = this.state.tickets.concat();
    pagina = pagina - 1;
    tickets = tickets.slice(pagina * LIMIT, (pagina + 1) * LIMIT);
    pagina = pagina + 1;

    let paginacao = this.state.paginacao;
    paginacao.pagina = pagina;
    paginacao.totalItens = this.state.tickets.length;
    this.setState({ ticketsPaginados: tickets, paginacao });
  }

  render() {
    const ListaApontamentos = (props) => (
      <tr className="print-table">
        <td colSpan="6" style={{ borderTop: "0px" }} className="td-grid-print">
          <div className="titulo-lista-apontamentos">Apontamentos</div>
          {props.apontamentos.map((apontamento, key) => (
            <React.Fragment>
              <div className="item-apontamento">
                <div className="item-apontamento-print">
                  <b>Atendente:</b> {apontamento.usuario}
                </div>
                <div className="item-apontamento-print">
                  <b>Tipo de Estória:</b> {apontamento.estoria}
                </div>
                <div className="item-apontamento-print">
                  <b>Data:</b> {apontamento.data}
                </div>
                <div className="item-apontamento-print">
                  <b>Hora Inicial:</b> {apontamento.horaInicial}
                </div>
                <div className="item-apontamento-print-descricao">
                  <b>Descrição:</b> {apontamento.descricao}
                </div>
              </div>
            </React.Fragment>
          ))}

          <div className="linha"></div>
          <br />
        </td>
      </tr>
    );

    const CabecalhoImpressao = () => (
      <div className="cabecalho-impressao" style={{ display: "none" }}>
        <img
          className="img-responsive"
          src="./images/logo-black.png"
          width="200"
          alt="logo"
        />
        <div className="pull-right">
          Gerado em : 05/09/2019
          <br />
          Usuário: Admin
        </div>
      </div>
    );

    return (
      <div>
        <div id="contentSite" className={className}>
          <div className="">
            <div className="container">
              <div className="Example_box">
                <div
                  style={{ padding: "15px 30px", fontSize: "14px" }}
                  className="font-tickets"
                >
                  {this.state.filtro !== null && (
                    <div
                      className="row row-lista-tickets"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className="form-group col-md-2 group-tickets noprint">
                        <label
                          for="inputCity"
                          className="label-form"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Código
                        </label>
                        <input
                          type="text"
                          className="form-control input-sm input-filter"
                          value={this.state.filtro.pesquisa.codigo}
                          onChange={(e) =>
                            this.handleInputFiltroChange(e, "codigo")
                          }
                        />
                      </div>

                      <div className="form-group col-md-2 group-tickets noprint">
                        <label
                          for="inputCity"
                          className="label-form"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Situação
                        </label>
                        <select
                          className="form-control input-sm input-filter"
                          value={this.state.filtro.pesquisa.situacao}
                          onChange={(e) =>
                            this.handleInputFiltroChange(e, "situacao")
                          }
                        >
                          <option value="">Todos</option>
                          <option value="0">Pendente</option>
                          <option value="1">Agendado</option>
                          <option value="2">Atendendo</option>
                          <option value="3">Parado</option>
                          <option value="4">Finalizado</option>
                        </select>
                      </div>

                      <div className="form-group col-md-2 group-tickets noprint">
                        <label
                          for="inputCity"
                          className="label-form"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Tipo
                        </label>
                        <select
                          className="form-control input-sm input-filter"
                          value={this.state.filtro.pesquisa.tipo}
                          onChange={(e) =>
                            this.handleInputFiltroChange(e, "tipo")
                          }
                        >
                          <option value="">Todos</option>
                          <option value="Suporte">Suporte</option>
                          <option value="Atendimento Presencial">
                            Atendimento Presencial
                          </option>
                          <option value="Atendimento Remoto">
                            Atendimento Remoto
                          </option>
                          <option value="Atendimento Não Franqueado">
                            Atendimento Não Franqueado
                          </option>
                          <option value="Atendimento Horário Especial">
                            Atendimento Horário Especial
                          </option>
                          <option value="Desenvolvimento Sob Medida">
                            Desenvolvimento Sob Medida
                          </option>
                        </select>
                      </div>

                      <div className="form-group col-md-2 group-tickets noprint">
                        <label
                          for="inputCity"
                          className="label-form"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          Período
                        </label>
                        <select
                          className="form-control input-sm input-filter"
                          value={this.state.filtro.pesquisa.periodo}
                          onChange={(e) =>
                            this.handleInputFiltroChange(e, "periodo")
                          }
                        >
                          <option value="0">Mês Atual</option>
                          <option value="1">Mês Passado</option>
                          {/* <option value="2">Últimos 30 dias</option>
                              <option value="3">Últimos 60 dias</option> */}
                        </select>
                      </div>

                      <div
                        className="form-group col-md-2 noprint"
                        style={{ marginTop: "22px", padding: "0" }}
                      >
                        <button
                          className="btn btn-secondary btn-sm"
                          id="btn-eraser-todos-tickets"
                          style={{
                            padding: ".275rem .55rem",
                            margin: "10px 5px 0px 5px",
                          }}
                          onClick={() => this.actionFiltrar()}
                        >
                          <i className="fa fa-filter" aria-hidden="true"></i>
                        </button>
                        <UncontrolledTooltip
                          placement="right"
                          target="btn-eraser-todos-tickets"
                        >
                          Filtrar
                        </UncontrolledTooltip>

                        <button
                          className="btn btn-danger btn-sm noprint"
                          id="btn-filter-todos-tickets"
                          style={{
                            padding: ".275rem .55rem",
                            margin: "10px 5px 0px 5px",
                          }}
                          onClick={() => this.limparFiltro()}
                        >
                          <i className="fa fa-eraser" aria-hidden="true"></i>
                        </button>
                        <UncontrolledTooltip
                          placement="right"
                          target="btn-filter-todos-tickets"
                        >
                          Limpar filtro
                        </UncontrolledTooltip>

                        <button
                          className="btn btn-info btn-sm noprint"
                          id="btn-imprimir-tickets"
                          style={{
                            padding: ".275rem .55rem",
                            margin: "10px 5px 0px 5px",
                          }}
                          onClick={() => window.print()}
                        >
                          <i className="fa fa-print" aria-hidden="true"></i>
                        </button>
                        <UncontrolledTooltip
                          placement="right"
                          target="btn-imprimir-tickets"
                        >
                          Imprimir Tickets
                        </UncontrolledTooltip>

                        {/* <button
                          className="btn btn-success btn-sm noprint"
                          id="btn-criar-ticket"
                          style={{
                            padding: ".275rem .55rem",
                            margin: "10px 5px 0px 5px",
                          }}
                          onClick={() =>
                            this.props.history.push("/tickets/novo")
                          }
                        >
                          <i className="fa fa-ticket" aria-hidden="true"></i>
                        </button> */}
                        {/* <UncontrolledTooltip
                          placement="right"
                          target="btn-criar-ticket"
                        >
                          Criar Ticket
                        </UncontrolledTooltip> */}
                      </div>
                    </div>
                  )}

                  <CabecalhoImpressao />

                  {this.state.exibirLoading && (
                    <table
                      className="table table-hover table-striped"
                      style={{ fontSize: "14px" }}
                    >
                      <div className="container-loading">
                        <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                          <div class="ldio-jz4kbaf040e">
                            <div></div>
                            <div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </table>
                  )}

                  {this.state.exibirMensagemTickets &&
                    !this.state.exibirLoading && (
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px" }}
                      >
                        <div
                          style={{
                            float: "left",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "10px",
                          }}
                        >
                          Nenhum ticket encontrado
                        </div>
                      </table>
                    )}

                  {!this.state.exibirMensagemTickets &&
                    !this.state.exibirLoading && (
                      <React.Fragment>
                        <table
                          className="table table-hover table-striped"
                          style={{ fontSize: "14px" }}
                        >
                          <thead className="thead-dark-custom thead-site thead-print">
                            <tr>
                              <th
                                style={{ maxWidth: "115px" }}
                                onClick={() => this.ordenar("codigo")}
                              >
                                Código
                              </th>
                              <th
                                style={{ maxWidth: "120px" }}
                                onClick={() => this.ordenar("situacao")}
                              >
                                Situação
                              </th>
                              <th
                                style={{ maxWidth: "270px" }}
                                onClick={() => this.ordenar("tipo")}
                              >
                                Tipo
                              </th>
                              <th onClick={() => this.ordenar("assunto")}>
                                Assunto
                              </th>
                              <th
                                style={{ maxWidth: "150px" }}
                                onClick={() => this.ordenar("usuarioDescricao")}
                              >
                                Atendente
                              </th>
                              <th
                                style={{ maxWidth: "150px" }}
                                onClick={() => this.ordenar("usuarioDescricao")}
                              >
                                Finalização
                              </th>
                              <th className="noprint">Detalhe</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.tickets.length > 0 &&
                              this.state.ticketsPaginados.map((ticket, key) => {
                                return (
                                  <React.Fragment>
                                    <tr key={key} className="tr-grid">
                                      <th
                                        scope="row"
                                        style={{ maxWidth: "115px" }}
                                      >
                                        {ticket.codigo}
                                      </th>
                                      <td
                                        style={{
                                          maxWidth: "120px",
                                          minWidth: "110px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            width: "100%",
                                            fontWeight: "bold",
                                            maxWidth: "150px",
                                          }}
                                        >
                                          <span
                                            className={
                                              "tag " + ticket.situacaoClass
                                            }
                                          >
                                            {ticket.situacao}
                                          </span>
                                        </span>
                                      </td>
                                      <td style={{ maxWidth: "270px" }}>
                                        {ticket.tipo}
                                      </td>
                                      <td style={{ maxWidth: "480px" }}>
                                        {ticket.contestacao !== null &&
                                          ticket.contestacao.aceita ===
                                            null && (
                                            <React.Fragment>
                                              <i
                                                class="fa fa-exclamation-circle icon-contestacao"
                                                aria-hidden="true"
                                                id={`contestacao-${ticket._id}`}
                                              ></i>{" "}
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={
                                                  "contestacao-" + ticket._id
                                                }
                                              >
                                                Ticket contestado
                                              </UncontrolledTooltip>
                                            </React.Fragment>
                                          )}
                                        {ticket.assuntoResumido}
                                      </td>
                                      <td style={{ maxWidth: "150px" }}>
                                        {ticket.usuarioDescricao}
                                      </td>
                                      <td style={{ maxWidth: "150px" }}>
                                        {ticket.dataFinalizacaoString}
                                      </td>

                                      <td className="noprint">
                                        <Link
                                          to={ticket.type === 'ticket' ? `/tickets/detail/${ticket._id}`:`/pre-tickets/detail/${ticket._id}`}
                                        >
                                          <Button
                                            color="secondary"
                                            id={"tooltip-" + ticket._id}
                                            style={{
                                              padding: ".275rem .55rem",
                                            }}
                                          >
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            ></i>
                                          </Button>
                                          <UncontrolledTooltip
                                            placement="right"
                                            target={"tooltip-" + ticket._id}
                                          >
                                            Visualizar Ticket
                                          </UncontrolledTooltip>
                                        </Link>
                                      </td>
                                    </tr>
                                    {ticket.apontamentos.length > 0 ? (
                                      <ListaApontamentos
                                        apontamentos={ticket.apontamentos}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                        <div
                          className="noprint"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          {this.state.paginacao.totalItens > 0 && (
                            <div className="paginator">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={
                                  this.state.paginacao.totalItens
                                }
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ticket;
