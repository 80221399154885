import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { ToastConsumer } from "react-awesome-toasts";
import "./Requisito.css";
import Pagination from "react-js-pagination";

const REQUISITO_AGUARDANDO = 2;
const REQUISITO_DUVIDA = 3;
const REQUISITO_APROVADO = 4;
const REQUISITO_CANCELADO = 5;

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

const getDescricaoSituacao = (situacao) => {
  let descricao = "";

  switch (situacao) {
    case 2:
      descricao = "Novo";
      break;

    case 3:
      descricao = "Dúvida";
      break;

    case 4:
      descricao = "Aprovado";
      break;

    case 5:
      descricao = "Cancelado";
      break;

    default:
      break;
  }

  return descricao;
};

class Requisito extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadMotivos = this.loadMotivos.bind(this);
    this.loadRequisitos = this.loadRequisitos.bind(this);
    this.handleClickDuvida = this.handleClickDuvida.bind(this);
    this.handleClickCancelar = this.handleClickCancelar.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    this.handleChangeInputModal = this.handleChangeInputModal.bind(this);
    this.handleClickAprovar = this.handleClickAprovar.bind(this);
    this.atualizaHorasAprovadas = this.atualizaHorasAprovadas.bind(this);
    this.definirNome = this.definirNome.bind(this);
    this.toggleModalDescricao = this.toggleModalDescricao.bind(this);
    this.handleClickMaisDetalhes = this.handleClickMaisDetalhes.bind(this);
    this.createMarkup = this.createMarkup.bind(this);
    this.getCountRequisitos = this.getCountRequisitos.bind(this);
    this.paginar = this.paginar.bind(this);

    this.state = {
      motivosDuvida: [],
      motivosCancelamento: [],
      requisitos: [],
      modal: {
        isOpen: false,
        titulo: "",
        motivos: [],
        exibeInput: false,
        textoInput: "",
        idRequisito: null,
        acao: null,
        mensagem: "",
      },
      totalHoras: "00:00:00",
      modalDescricao: false,
      modalTitulo: "",
      modalConteudo: "",
      loadingRequest: false,
      textoBotao: "Confirmar",
      totalDuvida: 0,
      totalCancelados: 0,
      modalConsultor: "",
      requisitoSelecionado: null,
      modalContato: "",
      modalHorasCobrada: "",
      modalDataEmissao: "",
      exibirLoading: false,
      exibirMensagemTickets: false,
      paginacao: {
        pagina: 1,
        limite: 15,
        totalItens: 0,
      }
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadRequisitos();
    await this.loadMotivos();
  }

  async loadRequisitos() {
    this.setState({ exibirLoading: true });
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/requisitos?pagina=${
          this.state.paginacao.pagina
        }`,
        this.props.parameters()
      )
      .then((response) => {


        let totalDuvida = 0;

        let requisitos = response.data.requisitos;
        for (let requisito of requisitos) {
          requisito.situacaoUpdate = false;
          if (requisito.situacao === REQUISITO_DUVIDA) {
            totalDuvida++;
          }
        }

        let exibirMensagemTickets =
          response.data.requisitos.length === 0 ? true : false;

        this.setState({
          requisitos,
          totalDuvida,
          exibirLoading: false,
          exibirMensagemTickets,
          paginacao: {
            pagina: response.data.paginas.pagina,
            limite: response.data.paginas.limite,
            totalItens: response.data.paginas.totalRegistros,
          }
        });
      });
  }

  async loadMotivos() {
    //TODO: AXIOS
    const promise1 = axios.get(
      `${this.props.getBaseUrlApi()}/motivos/duvida`,
      this.props.parameters()
    );
    const promise2 = axios.get(
      `${this.props.getBaseUrlApi()}/motivos/cancelamento`,
      this.props.parameters()
    );
    await Promise.all([promise1, promise2]).then((response) => {
      this.setState({
        motivosDuvida: response[0].data.motivos,
        motivosCancelamento: response[1].data.motivos,
      });
    });
  }

  handleClickDuvida(requisito) {
    let modal = this.state.modal;
    modal.titulo = "Dúvida Requisito";
    modal.isOpen = true;
    modal.motivos = this.state.motivosDuvida;
    modal.idRequisito = requisito.id;
    modal.acao = REQUISITO_DUVIDA;
    if (requisito.situacao === REQUISITO_DUVIDA) {
      if (requisito.situacaoUpdate) {
        let requisitos = this.state.requisitos;

        for (let item of requisitos) {
          if (item.id === requisito.id) {
            item.situacao = REQUISITO_AGUARDANDO;
          }
        }

        modal.isOpen = false;

        this.setState({ requisitos });
        this.getCountRequisitos();
      } else {
        let encontrou = false;
        for (let motivo of this.state.motivosDuvida) {
          if (motivo.descricao === requisito.msgDuvida) {
            encontrou = true;
          }
        }

        modal.exibeInput = true;
        modal.textoInput = requisito.msgDuvida;

        if (encontrou) {
          modal.mensagem = requisito.msgDuvida;
        } else {
          modal.mensagem = "outro";
        }
        this.setState({ modal });
      }
    } else {
      modal.exibeInput = false;
      modal.textoInput = "";
      modal.mensagem = "";
      this.setState({ modal });
    }
  }

  handleClickCancelar(requisito) {
    let modal = this.state.modal;
    modal.titulo = "Cancelar Requisito";
    modal.isOpen = true;
    modal.motivos = this.state.motivosCancelamento;
    modal.idRequisito = requisito.id;
    modal.acao = REQUISITO_CANCELADO;
    if (requisito.situacao === REQUISITO_CANCELADO) {
      let requisitos = this.state.requisitos;

      for (let item of requisitos) {
        if (item.id === requisito.id) {
          item.situacao = REQUISITO_AGUARDANDO;
        }
      }

      modal.isOpen = false;

      this.setState({ requisitos }, () => this.getCountRequisitos());
    } else {
      let encontrou = false;
      for (let motivo of this.state.motivosCancelamento) {
        if (motivo.descricao === requisito.msgCancelamento) {
          encontrou = true;
        }
      }
      if (encontrou) {
        modal.exibeInput = true;
        modal.mensagem = requisito.msgCancelamento;
        modal.textoInput = requisito.msgCancelamento;
      } else {
        if (requisito.msgCancelamento && requisito.msgCancelamento !== "") {
          modal.exibeInput = true;
          modal.textoInput = requisito.msgCancelamento;
          modal.mensagem = "outro";
        } else {
          modal.exibeInput = false;
          modal.textoInput = "";
          modal.mensagem = "0";
        }
      }

      this.setState({ modal });
    }
  }

  toggleModal() {
    let modal = this.state.modal;
    modal.isOpen = !this.state.modal.isOpen;
    this.setState({ modal });
  }

  handleModalChange(e) {
    let value = e.target.value;
    let modal = this.state.modal;
    if (value === "outro") {
      modal.textoInput = "";
      modal.exibeInput = true;
    } else if (value === "0") {
      modal.textoInput = "";
      modal.exibeInput = false;
    } else {
      modal.textoInput = value;
      modal.exibeInput = true;
    }
    modal.mensagem = value;
    this.setState({ modal });
  }

  handleChangeInputModal(e) {
    let modal = this.state.modal;
    let value = e.target.value;
    modal.textoInput = value;
    this.setState({ modal });
  }

  handleModalClick() {
    let modal = this.state.modal;
    if (modal.textoInput.length < 7) {
      alert("O Motivo deve possuir mais do que 7 caracteres.");
      return false;
    }

    let requisitos = this.state.requisitos;

    for (let item of requisitos) {
      if (item.id === modal.idRequisito) {
        if (modal.acao === REQUISITO_DUVIDA) {
          item.msgDuvida = modal.textoInput;
          item.situacaoUpdate = true;
        } else if (modal.acao === REQUISITO_CANCELADO) {
          item.msgCancelamento = modal.textoInput;
          item.situacaoUpdate = true;
        }
        item.situacao = modal.acao;
      }
    }

    modal.isOpen = false;
    modal.titulo = "";
    modal.motivos = [];
    modal.exibeInput = false;
    modal.textoInput = "";
    modal.idRequisito = null;
    modal.acao = null;
    modal.mensagem = "";

    try {
      this.atualizaHorasAprovadas();
    } catch (err) {
      alert(err);
    }

    this.setState({ modal, requisitos }, () => this.getCountRequisitos());
  }

  async handleClickAprovar(idRequisito) {
    let requisitos = this.state.requisitos;
    for (let item of requisitos) {
      if (item.id === idRequisito) {
        if (item.situacao === REQUISITO_APROVADO) {
          item.situacao = REQUISITO_AGUARDANDO;
        } else {
          item.situacao = REQUISITO_APROVADO;
        }
        item.msgDuvida = "";
        item.msgCancelamento = "";
        item.situacaoUpdate = true;
        await this.atualizaHorasAprovadas();
      }
    }
    this.setState({ requisitos }, () => this.getCountRequisitos());
  }

  atualizaHorasAprovadas() {
    let atual = "00:00:00";
    let requisitosAprovados = this.state.requisitos.filter(
      (value) => value.situacao === REQUISITO_APROVADO
    );

    if (requisitosAprovados.length) {
      for (let requisito of requisitosAprovados) {
        let tempo = requisito.horasCobrada;

        if (tempo.toString().indexOf(":") === -1) {
          throw new Error("error felipe"); //TODO: ALTERAR ERROR
        }

        if (tempo.split(":").length < 3) {
          throw new Error("error felipe");
        }

        let horasAtual = parseInt(atual.split(":")[0]);
        let minutosAtual = parseInt(atual.split(":")[1]);
        let segundosAtual = parseInt(atual.split(":")[2]);

        if (horasAtual > 0) {
          minutosAtual = minutosAtual + horasAtual * 60;
          horasAtual = 0;
        }

        let horasTempo = parseInt(tempo.split(":")[0]);
        let minutosTempo = parseInt(tempo.split(":")[1]);
        let segundosTempo = parseInt(tempo.split(":")[2]);

        if (horasTempo > 0) {
          minutosTempo = minutosTempo + horasTempo * 60;
          horasTempo = 0;
        }

        minutosAtual += minutosTempo;
        segundosAtual += segundosTempo;

        if (segundosAtual > 59) {
          let diferenca = parseInt(segundosAtual / 60);
          segundosAtual = segundosAtual - diferenca * 60;
          minutosAtual += diferenca;
        }

        if (minutosAtual > 59) {
          let diferenca = parseInt(minutosAtual / 60);
          minutosAtual = minutosAtual - diferenca * 60;
          horasAtual += diferenca;
        }

        atual =
          horasAtual.toString().padStart(2, "0") +
          ":" +
          minutosAtual.toString().padStart(2, "0") +
          ":" +
          segundosAtual.toString().padStart(2, "0");
      }
    }

    this.setState({ totalHoras: atual });
  }

  async definirNome({ toast }) {
    const toastProps = {
      text: "Requisitos Enviados",
      actionText: "Fechar",
      ariaLabel: "Requisitos enviados com sucesso, clique para fechar",
    };

    if (!this.state.loadingRequest) {
      await this.setState(
        { loadingRequest: true, textoBotao: "Enviando" },
        async () => {
          let lista = [...this.state.requisitos];

          let requisitos = lista.filter(
            (value) => value.situacao !== REQUISITO_AGUARDANDO
          );

          for (let requisito of requisitos) {
            delete requisito.codigo;
            delete requisito.titulo;
            delete requisito.descricao;
            delete requisito.horasCobrada;
            delete requisito.tipoEstoria;
          }

          if (requisitos.length) {
            await axios
              .put(
                `${this.props.getBaseUrlApi()}/requisitos`,
                { requisitos: requisitos },
                this.props.parameters()
              )
              .then((response) => {
                this.setState(
                  {
                    loadingRequest: false,
                    textoBotao: "Confirmar"
                  },
                  async() => {
                    await this.loadRequisitos();
                    toast.show({ ...toastProps, onActionClick: toast.hide });
                  }
                );
              });
          } else {
            this.setState({ loadingRequest: false, textoBotao: "Confirmar" });
          }
        }
      );
    }
  }

  handleClickMaisDetalhes(requisito) {
    let descricao = "";

    if (requisito.descricao !== null) {
      descricao = requisito.descricao.replace(/\r\n/g, "<br />");
    }

    this.setState(
      {
        modalTitulo: `${requisito.codigo} - ${requisito.titulo}`,
        modalConteudo: descricao,
        modalConsultor: requisito.consultor,
        modalContato: requisito.contato,
        modalHorasCobrada: requisito.horasCobrada,
        requisitoSelecionado: requisito,
        modalDataEmissao: moment(new Date(requisito.data)).format("DD/MM/YYYY"),
      },
      () => this.toggleModalDescricao()
    );
  }

  toggleModalDescricao() {
    this.setState({ modalDescricao: !this.state.modalDescricao });
  }

  createMarkup(html) {
    return { __html: html };
  }

  getCountRequisitos() {
    let requisitos = this.state.requisitos;
    let totalDuvida = 0;
    let totalCancelados = 0;

    for (let requisito of requisitos) {
      if (requisito.situacao === REQUISITO_DUVIDA) {
        totalDuvida++;
      } else if (requisito.situacao === REQUISITO_CANCELADO) {
        totalCancelados++;
      }
    }
    this.setState({ totalDuvida, totalCancelados });
  }

  paginar(pagina = 1) {
    let requisitos = [].concat(this.state.requisitos);
    let totalRequisitosAlterados = requisitos.filter(item => item.situacaoUpdate);

    if(totalRequisitosAlterados.length > 0){
      alert('Primeiro salve os requisitos editados para continuar.');
      return false;
    }

    this.setState({ paginacao: {...this.state.paginacao, pagina} }, async() => {
      await this.loadRequisitos();
    });
  }

  render() {
    const ButtonDuvida = (props) => {
      return (
        <React.Fragment>
          <Button
            color={"warning"}
            active={
              props.requisito.situacao === REQUISITO_DUVIDA ? true : false
            }
            onClick={() => this.handleClickDuvida(props.requisito)}
            id={`tooltip-duvida${props.requisito.id}${
              props.modal ? "modal" : ""
            }`}
            style={{ margin: "2px" }}
          >
            <i
              className="fa fa-exclamation-circle"
              aria-hidden="true"
              style={{ color: "#FFF" }}
            />
          </Button>
          <UncontrolledTooltip
            placement="right"
            target={`tooltip-duvida${props.requisito.id}${
              props.modal ? "modal" : ""
            }`}
          >
            Dúvida
          </UncontrolledTooltip>
        </React.Fragment>
      );
    };

    const ButtonCancelar = (props) => {
      return (
        <React.Fragment>
          <Button
            color={"danger"}
            active={
              props.requisito.situacao === REQUISITO_CANCELADO ? true : false
            }
            onClick={() => this.handleClickCancelar(props.requisito)}
            id={"tooltip-cancelar" + props.requisito.id}
            style={{ margin: "2px" }}
          >
            <i className="fa fa-times-circle" aria-hidden="true" />
          </Button>

          <UncontrolledTooltip
            placement="right"
            target={"tooltip-cancelar" + props.requisito.id}
          >
            Cancelar
          </UncontrolledTooltip>
        </React.Fragment>
      );
    };

    const ButtonAprovar = (props) => {
      return (
        <React.Fragment>
          <Button
            color={"success"}
            active={
              props.requisito.situacao === REQUISITO_APROVADO ? true : false
            }
            onClick={() => this.handleClickAprovar(props.requisito.id)}
            id={`tooltip-aprovar${props.requisito.id}${
              props.modal ? "modal" : ""
            }`}
            style={{ margin: "2px" }}
          >
            <i className="fa fa-check-circle" aria-hidden="true" />
          </Button>

          <UncontrolledTooltip
            placement="right"
            target={`tooltip-aprovar${props.requisito.id}${
              props.modal ? "modal" : ""
            }`}
          >
            Aprovar
          </UncontrolledTooltip>
        </React.Fragment>
      );
    };

    const ButtonVisualizar = (props) => {
      return (
        <React.Fragment>
          <Button
            color={"secondary"}
            onClick={() => this.handleClickMaisDetalhes(props.requisito)}
            id={`tooltip-visualizar`}
            style={{ margin: "2px" }}
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </Button>

          <UncontrolledTooltip placement="right" target={`tooltip-visualizar`}>
            Visualizar
          </UncontrolledTooltip>
        </React.Fragment>
      );
    };

    return (
      <div id="contentSite" className={className}>
        <div
          className=""
          style={{
            height: "50px",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#2c2e3e!important",
            color: "#FFF",
            textAlign: "right",
            backgroundColor: "#2c2e3e",
            zIndex:"9999"
          }}
        >
          <span
            style={{
              position: "relative",
              marginRight: "30px",
              fontSize: "16px",
              top: "8px",
            }}
          >
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
            Requisitos com dúvida: {this.state.totalDuvida}
          </span>

          <span
            style={{
              position: "relative",
              marginRight: "30px",
              fontSize: "16px",
              top: "8px",
            }}
          >
            <i className="fa fa-times-circle" aria-hidden="true"></i> Requisitos
            cancelados: {this.state.totalCancelados}
          </span>

          <span
            style={{
              position: "relative",
              marginRight: "30px",
              fontSize: "16px",
              top: "8px",
            }}
          >
            <i className="fa fa-check-circle" aria-hidden="true"></i> Horas
            Aprovadas {this.state.totalHoras}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ToastConsumer>
              {({ show, hide }) => (
                <button
                  className="btn btn-warning"
                  onClick={() => this.definirNome({ toast: { show, hide } })}
                  style={{
                    backgroundColor: "#ffae00",
                    borderColor: "#ffae00",
                    minWidth: "112px",
                  }}
                  disabled={this.state.requisitos.length === 0 ? true : false}
                >
                  {this.state.textoBotao}{" "}
                  {this.state.loadingRequest && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              )}
            </ToastConsumer>
          </span>
        </div>

        <div className="">
          <div className="container">
            <div className="Example_box">
              <div
                style={{ padding: "15px 30px", fontSize: "14px" }}
                className="font-tickets"
              >
                <div
                  className="row row-lista-tickets"
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="form-group col-md-10 noprint"
                    style={{ marginTop: "0", padding: "0", textAlign:"left"}}
                  >
                    <span style={{marginLeft:"5px", fontWeight:"bold", color:"#6c757d"}}>
                      Após marcar os requisitos como aprovado, clique em
                      confirmar no rodapé da página.
                    </span>
                  </div>

                  {/* <div
                    className="form-group col-md-2 noprint"
                    style={{ marginTop: "0", padding: "0", textAlign:"right", paddingRight:"8px" }}
                  >
                    <button
                      className="btn btn-success btn-sm noprint"
                      id="btn-criar-ticket"
                      style={{
                        padding: ".275rem .55rem",
                        margin: "10px 5px 0px 5px",
                      }}
                      onClick={() =>
                        this.props.history.push("/requisitos/novo")
                      }
                    >
                      <i
                        className="fa fa-list-ul"
                        id="btn-criar-requisito"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <UncontrolledTooltip
                      placement="right"
                      target="btn-criar-requisito"
                    >
                      Criar Requisito
                    </UncontrolledTooltip>
                  </div> */}
                </div>

                {this.state.exibirLoading && (
                  <table
                    className="table table-hover table-striped"
                    style={{ fontSize: "14px" }}
                  >
                    <div className="container-loading">
                      <div class="loadingio-spinner-dual-ring-oingaqu2jlh">
                        <div class="ldio-jz4kbaf040e">
                          <div></div>
                          <div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  felipe */}
                  </table>
                )}

                {this.state.exibirMensagemTickets && !this.state.exibirLoading && (
                  <table
                    className="table table-hover table-striped"
                    style={{ fontSize: "14px" }}
                  >
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "16px",
                        padding: "10px",
                      }}
                    >
                      Nenhum requisito encontrado
                    </div>
                  </table>
                )}

                {!this.state.exibirMensagemTickets &&
                  !this.state.exibirLoading && (
                    <React.Fragment>
                      <table
                        className="table table-hover table-striped"
                        style={{ fontSize: "14px", marginBottom: "100px" }}
                      >
                        <thead className="thead-dark-custom">
                          <tr>
                            <th style={{ maxWidth: "60px" }}>Código</th>
                            <th style={{ maxWidth: "98px" }}>Situação</th>
                            <th>Título</th>
                            <th style={{ maxWidth: "50px" }}>Horas</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.requisitos.map((requisito, key) => (
                            <tr key={key}>
                              <th scope="row" style={{ maxWidth: "50px" }}>
                                {requisito.codigo}
                              </th>
                              <td style={{ maxWidth: "70px" }}>
                                <span
                                  style={{ width: "100%", fontWeight: "bold" }}
                                >
                                  <span
                                    className={
                                      "tag " +
                                      getDescricaoSituacao(requisito.situacao)
                                    }
                                  >
                                    {getDescricaoSituacao(requisito.situacao)}
                                  </span>
                                </span>
                              </td>
                              <td style={{ maxWidth: "480px" }}>
                                {requisito.titulo}
                              </td>
                              <td style={{ maxWidth: "50px" }}>
                                {requisito.horasCobrada}
                              </td>

                              <td>
                                <ButtonDuvida
                                  requisito={requisito}
                                  modal={false}
                                />

                                <ButtonCancelar
                                  requisito={requisito}
                                  modal={false}
                                />

                                <ButtonAprovar
                                  requisito={requisito}
                                  modal={false}
                                />

                                <ButtonVisualizar requisito={requisito} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                          className="noprint"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginBottom: "20px",
                            paddingBottom: "20px"
                          }}
                        >
                          {this.state.paginacao.totalItens > 0 && (
                            <div className="paginator">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={
                                  this.state.paginacao.totalItens
                                }
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div>
                          )}
                        </div>
                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal.isOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            {this.state.modal.titulo}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Comentário:</Label>
              <select
                className="form-control"
                onChange={this.handleModalChange}
                value={this.state.modal.mensagem}
              >
                <option value="0">Selecione</option>

                {this.state.modal.motivos.map((motivo, key) => (
                  <option key={key} value={motivo.descricao}>
                    {motivo.descricao}
                  </option>
                ))}
                <option value="outro">Outro</option>
              </select>
            </FormGroup>

            <FormGroup
              style={
                this.state.modal.exibeInput
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Label>Comentário:</Label>
              <Input
                type="textarea"
                onChange={this.handleChangeInputModal}
                disabled={!this.state.modal.exibeInput}
                value={this.state.modal.textoInput}
                rows={5}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleModalClick}>
              Salvar
            </Button>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalDescricao}
          toggle={this.toggleModalDescricao}
          className="modal-bg"
        >
          <div
            className="modal-header modal-footer-bg"
            toggle={this.toggleModalDescricao}
            style={{ fontSize: "15px" }}
          >
            {this.state.modalTitulo}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Fechar"
              onClick={this.toggleModalDescricao}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div
            className="modal-body"
            dangerouslySetInnerHTML={this.createMarkup(
              this.state.modalConteudo
            )}
            style={{
              overflowY: "scroll",
              maxHeight: "220px",
              fontSize: "15px",
              minHeight: "220px",
            }}
          />

          {this.state.requisitoSelecionado !== null && (
            <ModalFooter className="modal-footer-bg">
              <div style={{ float: "left", width: "100%" }}>
                <div className="item-modal-descricao">
                  <span
                    style={{
                      minWidth: "260px",
                      maxWidth: "260px",
                      float: "left",
                    }}
                  >
                    <b>Consultor:</b> {this.state.modalConsultor}
                  </span>
                  <b>Solicitante:</b> {this.state.modalContato}
                </div>
                <div className="item-modal-descricao">
                  <span
                    style={{
                      minWidth: "260px",
                      maxWidth: "260px",
                      float: "left",
                    }}
                  >
                    <b>Data de emissão:</b> {this.state.modalDataEmissao}
                  </span>
                  <b>Total de Horas:</b> {this.state.modalHorasCobrada}
                </div>
              </div>

              <ButtonDuvida
                requisito={this.state.requisitoSelecionado}
                modal={true}
              />

              <ButtonCancelar
                requisito={this.state.requisitoSelecionado}
                modal={true}
              />

              <ButtonAprovar
                requisito={this.state.requisitoSelecionado}
                modal={true}
              />
            </ModalFooter>
          )}
        </Modal>
      </div>
    );
  }
}

export default Requisito;
