import React, { Component } from "react";
import api from "../../services/api";
import validator from "validator";
import {
  Button,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Modal,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { ToastConsumer } from "react-awesome-toasts";
import moment from "moment";
import FileBase64 from "react-file-base64";

const className =
  localStorage.getItem("menuCompacto") !== null
    ? localStorage.getItem("menuCompacto") === "true"
      ? "contentSite-menu-compacto"
      : ""
    : "";

export default class TicketNovo extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputContato = this.onChangeInputContato.bind(this);
    this.loadData = this.loadData.bind(this);
    this.validarDados = this.validarDados.bind(this);
    this.loadContatos = this.loadContatos.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.adicionarAnexo = this.adicionarAnexo.bind(this);
    this.toggle = this.toggle.bind(this);
    this.baixarAnexo = this.baixarAnexo.bind(this);

    this.state = {
      contato: null,
      assunto: "",
      descricao: "",
      prioridade: "Baixa",
      contatos: [],
      errors: {
        contato: false,
        assunto: false,
        descricao: false,
      },
      tipo: "Suporte",
      activeTab: "resumo",
      anexos: [],
      modal: false,
      obs: "",
      base64: "",
      telaProcesso: "",
      modulo: null
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    await this.loadContatos();
  }

  onChangeInputContato(contact) {
    this.setState({
      contato: contact,
    });
  }

  onChangeInput(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async validarDados() {
    let isValid = true;
    let errors = this.state.errors;

    if (this.state.contato === null) {
      errors.contato = true;
      isValid = false;
    } else {
      errors.contato = false;
    }

    if (validator.isEmpty(this.state.assunto, [{ ignore_whitespace: true }])) {
      errors.assunto = true;
      isValid = false;
    } else {
      errors.assunto = false;
    }

    if (
      validator.isEmpty(this.state.descricao, [{ ignore_whitespace: true }])
    ) {
      errors.descricao = true;
      isValid = false;
    } else {
      errors.descricao = false;
    }

    await this.setState({ errors });
    return isValid;
  }

  async onSubmit({ action, toast }) {
    const toastProps = {
      text: "Ticket criado com sucesso",
      actionText: "Fechar",
      ariaLabel: "Ticket criado com sucesso, clique para fechar",
    };

    let isValid = await this.validarDados();
    if (!isValid) return false;

    const objectToSend = {
      contato: this.state.contato,
      assunto: this.state.assunto,
      descricao: this.state.descricao,
      prioridade: this.state.prioridade,
      tipo: this.state.tipo,
      anexos: this.state.anexos,
      telaProcesso: this.state.telaProcesso,
      modulo: this.state.modulo
    };

    await api
      .post("tickets", objectToSend, this.props.parameters())
      .then((res) => {
        toast.show({ ...toastProps, onActionClick: toast.hide });

        action = "saveAndStart";

        switch (action) {
          case "save":
            this.props.history.push("/tickets");
            break;

          case "saveAndStart":
            this.props.history.push(`/pre-tickets/detail/${res.data.id}`);
            break;

          case "saveAndNew":
            this.setState({
              contato: null,
              assunto: "",
              descricao: "",
              prioridade: "Baixa",
              tipo: "Suporte",
              anexos: []
            });

            break;

          default:
            break;
        }
      });
  }

  async loadContatos() {
    await axios
      .get(`${this.props.getBaseUrlApi()}/contatos`, this.props.parameters())
      .then((response) => {
        let contatos = response.data.contatos;
        contatos = contatos.map((contato) => {
          contato.value = contato.descricao;
          contato.label = contato.descricao;
          return contato;
        });
        this.setState({ contatos });
      })
      .catch((err) => this.props.handlingErrorsApi(err, this.props));
  }

  handleChangeInput(value, type) {
    if(type === 'modulo' && value === "null"){
      value = null;
    }
    this.setState({ [type]: value }, () => {console.clear();console.log(this.state)});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getFiles(files) {
    this.setState({
      base64: files.base64,
    });

    console.log(files.base64);

    this.toggle();
  }

  async adicionarAnexo({ action, toast }) {
    let obs = this.state.obs;
    let anexos = this.state.anexos;

    const toastProps = {
      text: "Anexo adicionado com sucesso!",
      actionText: "Fechar",
      ariaLabel: "Anexo adicionado com sucesso!, clique para fechar",
    };

    if (obs === "") {
      alert("Favor informar a observação");
      return false;
    }

    anexos.push({
      desc: obs,
      dtGravacao: new Date(),
      base64: this.state.base64,
      url: "",
    });

    this.setState({ anexos, obs: "", base64: "" }, () => {
      this.toggle();

      toast.show({ ...toastProps, onActionClick: toast.hide });
    });

    // let atendimento = this.state.atendimento;

    // let novoAnexo = {
    //   _id: uuidv4(),
    //   obs: this.state.obs,
    //   data: new Date(),
    //   base64: this.state.base64,
    //   idAtendimento: this.state.idAtendimento,
    //   idAtendimentoApp: this.state.atendimento._id,
    //   type: "anexo",
    //   usuarioGravacao: JSON.parse(localStorage.getItem("usuario")).codigo,
    //   isFichaAnestesica,
    //   isFichaPreHospitalar,
    // };

    // atendimento.anexos.push(novoAnexo);
    // await this.state.db.put(novoAnexo);

    // this.setState({ atendimento }, () => {
    //   this.toggle();
    //   this.setState({ obs: "" });
    //   this.notificar("success", "Anexo adicionado com sucesso");
    // });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async baixarAnexo(base64) {
    const linkSource = base64;
    const downloadLink = document.createElement("a");
    const fileName = `${new Date().getTime()}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  render() {
    return (
      <div id="contentSite" className={className}>
        <div className="container">
          <div className="Example_box">
            <div className="Example_box-content">
              <Col sm={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "resumo" }}
                      onClick={() => {
                        this.toggleTab("resumo");
                      }}
                    >
                      Resumo
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: this.state.activeTab === "anexos" }}
                      onClick={() => {
                        this.toggleTab("anexos");
                      }}
                    >
                      Anexos
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="resumo">
                    <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>
                      <Form>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Contato
                            </label>

                            {this.state.contatos.length ? (
                              <Select
                                value={this.state.contato}
                                onChange={this.onChangeInputContato}
                                options={this.state.contatos}
                                placeholder="Selecione"
                              />
                            ) : (
                              <select
                                className="form-control"
                                id="contato"
                                disabled={true}
                              ></select>
                            )}
                            {this.state.errors.contato && (
                              <div className="is-invalid-feedback">
                                Por favor selecione um contato.
                              </div>
                            )}
                          </div>

                          <div className="form-group col-md-4">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Tipo
                            </label>
                            <select
                              className="form-control"
                              id="prioridade"
                              value={this.state.tipo}
                              onChange={(e) =>
                                this.handleChangeInput(e.target.value, "tipo")
                              }
                            >
                              <option value="Suporte">Suporte</option>
                              <option value="Consultoria">Consultoria</option>
                              <option value="Desenvolvimento">
                                Desenvolvimento
                              </option>
                            </select>
                          </div>

                          <div className="form-group col-md-4">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Assunto
                            </label>

                            <Input
                              type="text"
                              id="assunto"
                              value={this.state.assunto}
                              onChange={this.onChangeInput}
                              className={
                                this.state.errors.assunto ? "is-invalid" : ""
                              }
                            />

                            {this.state.errors.assunto && (
                              <div className="is-invalid-feedback">
                                Por favor digite um assunto.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">

                        <div className="form-group col-md-4">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Tela/Processo
                            </label>

                            <Input
                              type="text"
                              id="telaProcesso"
                              value={this.state.telaProcesso}
                              onChange={this.onChangeInput}
                            />
                          </div>

                          <div className="form-group col-md-4">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Módulo Priorize
                            </label>
                            <select
                              className="form-control"
                              id="prioridade"
                              value={this.state.modulo}
                              onChange={(e) =>
                                this.handleChangeInput(e.target.value, "modulo")
                              }
                            >
                              <option value="null">Selecione</option>
                              <option value="Básico">Básico</option>
                              <option value="Comercial">Comercial</option>
                              <option value="CRM">CRM</option>
                              <option value="Estoque">Estoque</option>
                              <option value="Faturamento">Faturamento</option>
                              <option value="Fiscal">Fiscal</option>
                              <option value="Financeiro">Financeiro</option>
                              <option value="PCP">PCP</option>
                              <option value="Suprimentos">Suprimentos</option>
                              <option value="WMS">WMS</option>

                            </select>
                          </div>


                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-12">
                            <label
                              htmlFor="exampleEmail"
                              className="label-form"
                            >
                              Descrição
                            </label>
                            <small
                              style={{ display: "block", color: "#656363" }}
                            >
                              Descreva detalhes que nos ajudem a entender a sua
                              necessidade. Isso agilizará o atendimento
                            </small>
                            <textarea
                              id="descricao"
                              value={this.state.descricao}
                              onChange={this.onChangeInput}
                              rows={6}
                              className={
                                this.state.errors.descricao
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {this.state.errors.descricao && (
                              <div className="is-invalid-feedback">
                                Por favor digite uma descrição.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-group col-sm-12 div-center">
                          <ToastConsumer>
                            {({ show, hide }) => (
                              <Button
                                color="success"
                                className="button-novo-ticket"
                                type="button"
                                style={{ margin: "5px" }}
                                onClick={() =>
                                  this.onSubmit({
                                    action: "save",
                                    toast: { show, hide },
                                  })
                                }
                              >
                                <i
                                  className="fa fa-floppy-o"
                                  aria-hidden="true"
                                ></i>{" "}
                                Salvar
                              </Button>
                            )}
                          </ToastConsumer>

                          {/* <ToastConsumer>
                      {({ show, hide }) => (
                        <Button
                          color="success"
                          className="button-novo-ticket"
                          type="button"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            this.onSubmit({
                              action: "saveAndStart",
                              toast: { show, hide },
                            })
                          }
                        >
                          <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                          Salvar/Ver
                        </Button>
                      )}
                    </ToastConsumer> */}

                          {/* <ToastConsumer>
                      {({ show, hide }) => (
                        <Button
                          color="success"
                          className="button-novo-ticket"
                          type="button"
                          style={{ margin: "5px" }}
                          onClick={() =>
                            this.onSubmit({
                              action: "saveAndNew",
                              toast: { show, hide },
                            })
                          }
                        >
                          <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                          Salvar/Novo
                        </Button>
                      )}
                    </ToastConsumer> */}

                          <Button
                            className="button-novo-ticket"
                            type="button"
                            style={{ margin: "5px" }}
                            onClick={() => this.props.history.push("/tickets")}
                          >
                            <i
                              className="fa fa-arrow-circle-o-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Voltar
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </TabPane>
                  <TabPane tabId="anexos">
                    <div style={{ paddingTop: "15px", paddingBottom: "20px" }}>
                      <table className="table table-bordered table-striped table-hover">
                        <thead className="thead-dark-custom thead-site">
                          <th style={{ borderTop: "none" }}>Descrição</th>
                          <th
                            style={{
                              borderTop: "none",
                              textAlign: "center",
                            }}
                          >
                            Data
                          </th>
                          <th className="text-center">Ações</th>
                        </thead>
                        <tbody>
                          {this.state.anexos.length > 0 &&
                            this.state.anexos.map((anexo, key) => (
                              <tr key={key}>
                                <td>{anexo.desc}</td>
                                <td className="text-center">
                                  {moment(anexo.dtGravacao).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td className="text-center">
                                  <div style={{ margin: "3px" }}>
                                    <i
                                      style={{
                                        fontSize: "22px",
                                        cursor: "pointer",
                                        color: "#28a745",
                                      }}
                                      className="fa fa-download"
                                      onClick={() => {
                                        this.baixarAnexo(anexo.base64);
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <div
                        className="float-left w-100 d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <FileBase64
                          multiple={false}
                          onDone={(e) => this.getFiles(e)}
                          id="file-send"
                          style="form-control"
                        />
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
                {/* </div> */}
              </Col>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Adicionar Anexo</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggle}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label>Observação</label>
                <textarea
                  className="form-control"
                  onChange={(e) =>
                    this.handleChangeInput(e.target.value, "obs")
                  }
                >
                  {this.state.obs}
                </textarea>
              </div>
            </div>
            <div className="modal-footer">
              <div style={{ margin: "3px" }}>
                <ToastConsumer>
                  {({ show, hide }) => (
                    <button
                      className="btn btn-outline-info"
                      //handleClick={props.handleButtonClick}
                      onClick={() =>
                        this.adicionarAnexo({ toast: { show, hide } })
                      }
                    >
                      Salvar
                    </button>
                  )}
                </ToastConsumer>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
