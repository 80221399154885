const getStatusName = (status) => {
  let description = '';
  switch (status) {
    case 0:
      description = 'Pendente';
      break;

    case 1:
      description = 'Agendado';
      break; case 2:
      description = 'Atendendo';
      break;

    case 3:
      description = 'Parado';
      break;

    case 4:
      description = 'Finalizado';
      break;

    case 5:
      description = 'Cancelado';
      break;

    case 6:
      description = 'Em Análise';
    break;

    default:
      description = '';
      break;
  }

  return description;
}

const atualizaActions = (status, usuarioIniciouAtendimento) => {
  let actions = {};
  switch (status) {
    case 0:
      actions = {
        iniciarAtendimento: true,
        cancelarAtendimento: true,
        finalizarAtendimento: true,
        pausarAtendimento: false
      }
      break;

    case 1:
      actions = {
        iniciarAtendimento: true,
        cancelarAtendimento: true,
        finalizarAtendimento: true,
        pausarAtendimento: false
      }

      break;

    case 2:
      if (usuarioIniciouAtendimento) {
        actions.pausarAtendimento = true;
        actions.finalizarAtendimento = true;
      } else {
        actions.pausarAtendimento = false;
        actions.finalizarAtendimento = false;
      }
      actions.iniciaAtendimento = false;
      actions.cancelarAtendimento = false;
      break;

    case 3:
      actions = {
        iniciarAtendimento: true,
        finalizarAtendimento: true,
        pausarAtendimento: false,
        cancelarAtendimento: false
      }
      break;

    case 4:
      actions = {
        iniciarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false,
        cancelarAtendimento: false
      }
      break;

    case 5:
      actions = {
        iniciarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false,
        cancelarAtendimento: false
      }
      break;

    default:
      actions = {
        iniciarAtendimento: false,
        cancelarAtendimento: false,
        finalizarAtendimento: false,
        pausarAtendimento: false
      }
      break;
  }
  return actions;
}

const getStatusClassName = (status) => {
  let description = '';
  switch (status) {
    case 0:
      description = 'aFazer';
      break;

    case 1:
      description = 'agendado';
      break; 
    
    case 2:
      description = 'atendendo';
      break;

    case 3:
      description = 'parado';
      break;

    case 4:
      description = 'finalizado';
      break;

    case 5:
      description = 'cancelado';
      break;

    case 6:
      description = 'emAnalise';
      break;

    default:
      description = '';
      break;
  }

  return description;
}

const getStatusClassNamePreTicket = (situacaoPortalCliente) => {
  let className = "";
  switch (situacaoPortalCliente) {
    case "Pendente":
      className = "pendente";
      break;

    case "Em análise":
      className = "emAnalise";
      break; 
    
    case "Cancelado":
      className = "cancelado";
      break;

    default:
      className = "";
      break;
  }

  return className;
}


module.exports = { getStatusName, atualizaActions, getStatusClassName, getStatusClassNamePreTicket };