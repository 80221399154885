import React, { Component } from "react";
import { Col, Row, UncontrolledTooltip, Tooltip } from "reactstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import axios from "axios";
import "./Home.css";

const className = localStorage.getItem('menuCompacto') !== null ? localStorage.getItem('menuCompacto') === 'true' ? 'contentSite-menu-compacto' : '' : '';

const dataOptions = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#EF3F5C", "#514C90"],
      hoverBackgroundColor: ["#EF3F5C", "#514C90"],
    },
  ],
};

const dataGrafico = {
  labels: [],
  datasets: [
    {
      label: "Total de Horas",
      backgroundColor: "#309A85",
      borderColor: "#309A85",
      borderWidth: 1,
      hoverBackgroundColor: "#309A85",
      hoverBorderColor: "#309A85",
      data: [],
    },
  ],
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.toogleMensagem = this.toogleMensagem.bind(this);
    this.handleClickMensagem = this.handleClickMensagem.bind(this);
    this.loadIndicadoresAtendimento =
      this.loadIndicadoresAtendimento.bind(this);
    this.loadAtendimentos = this.loadAtendimentos.bind(this);
    this.loadHistoricoAtendimento = this.loadHistoricoAtendimento.bind(this);
    this.loadIndicadoresConsultoria =
      this.loadIndicadoresConsultoria.bind(this);
    this.loadIndicadoresPendencia = this.loadIndicadoresPendencia.bind(this);
    this.loadMensagens = this.loadMensagens.bind(this);

    this.state = {
      data: dataOptions,
      dataGrafico: dataGrafico,
      mensagens: [],
      saldo: "00:00",
      tempoConsumido: "00:00",
      tempoDisponivel: "00:00",
      consumoConsultoria: "00:00",
      totalTickets: 0,
      totalTicketsFinalizados: 0,
      totalTicketsAberto: 0,
      saldoExcedido: "00:00",
      mensagemAberta: null,
      indicadores: {
        indicadorAtendimentoPresencial: "00:00",
        indicadorAtendimentoPresencialEspecial: "00:00",
        indicadorRemoto: "00:00",
        indicadorRemotoEspecial: "00:00",
        indicadorDevSobMedida: "00:00",
      },
      consumoAtendimentoEspecial: "00:00",
      indicadoresPendencias: {
        priori: 0,
        cliente: 0,
        terceiros: 0,
      },
    };
  }

  async componentDidMount() {
    this.loadDadosStorage();
    this.loadIndicadoresAtendimento();
    this.loadAtendimentos();
    this.loadHistoricoAtendimento();
    this.loadIndicadoresConsultoria();
    this.loadIndicadoresPendencia();
    this.loadMensagens();
  }

  loadDadosStorage() {
    let saldo = this.state.saldo;
    let tempoConsumido = this.state.tempoConsumido;
    let tempoDisponivel = this.state.tempoDisponivel;
    let consumoAtendimentoEspecial = this.state.consumoAtendimentoEspecial;
    let saldoExcedido = this.state.saldoExcedido;

    let saldoStorage = localStorage.getItem("saldo");
    let tempoConsumidoStorage = localStorage.getItem("tempoConsumido");
    let tempoDisponivelStorage = localStorage.getItem("tempoDisponivel");
    let consumoAtendimentoEspecialStorage = localStorage.getItem(
      "consumoAtendimentoEspecial"
    );
    let saldoExcedidoStorage = localStorage.getItem("saldoExcedido");

    if (saldoStorage !== null) {
      saldo = saldoStorage;
    }
    if (tempoConsumidoStorage !== null) {
      tempoConsumido = tempoConsumidoStorage;
    }
    if (tempoDisponivelStorage !== null) {
      tempoDisponivel = tempoDisponivelStorage;
    }
    if (consumoAtendimentoEspecialStorage !== null) {
      consumoAtendimentoEspecial = consumoAtendimentoEspecialStorage;
    }
    if (saldoExcedidoStorage !== null) {
      saldoExcedido = saldoExcedidoStorage;
    }

    let totalTickets = this.state.totalTickets;
    let totalTicketsStorage = localStorage.getItem("totalTickets");

    if (totalTicketsStorage !== null) {
      totalTickets = totalTicketsStorage;
    }

    let data = this.state.data;
    let dataStorage = localStorage.getItem("data");

    if (dataStorage !== null) {
      dataStorage = JSON.parse(dataStorage);

      data.datasets[0].data = [
        dataStorage.totalTicketsAberto,
        dataStorage.totalTicketsFinalizados,
      ];
      data.labels = [];
      data.labels.push(`Pendentes (${dataStorage.totalTicketsAberto})`);
      data.labels.push(`Finalizados (${dataStorage.totalTicketsFinalizados})`);
    }

    let dataGrafico = this.state.dataGrafico;
    let dataGraficoStorage = localStorage.getItem("dataGrafico");

    if (dataGraficoStorage !== null) {
      dataGraficoStorage = JSON.parse(dataGraficoStorage);

      dataGrafico.labels = [];
      dataGrafico.datasets[0].data = [];

      for (let item of dataGraficoStorage) {
        dataGrafico.labels.push(item.mes);
        dataGrafico.datasets[0].data.push(item.tempoTotal);
      }
    }

    let indicadores = this.state.indicadores;
    let indicadoresStorage = localStorage.getItem("indicadores");

    if (indicadoresStorage !== null) {
      indicadoresStorage = JSON.parse(indicadoresStorage);
      indicadores = indicadoresStorage;
    }

    let indicadoresPendencias = this.state.indicadoresPendencias;
    let indicadoresPendenciasStorage = localStorage.getItem(
      "indicadoresPendencias"
    );

    if (indicadoresPendenciasStorage !== null) {
      indicadoresPendenciasStorage = JSON.parse(indicadoresPendenciasStorage);
      indicadoresPendencias = indicadoresPendenciasStorage;
    }

    let mensagens = this.state.mensagens;
    let mensagensStorage = localStorage.getItem("mensagens");

    if (mensagensStorage !== null) {
      mensagensStorage = JSON.parse(mensagensStorage);
      mensagens = mensagensStorage;
    }

    this.setState({
      saldo,
      tempoConsumido,
      tempoDisponivel,
      consumoAtendimentoEspecial,
      saldoExcedido,
      totalTickets,
      data,
      dataGrafico,
      indicadores,
      indicadoresPendencias,
      mensagens,
    });
  }

  async loadIndicadoresAtendimento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/indicadores-atendimento`,
        this.props.parameters()
      )
      .then((response) => {
        let saldoTemp =
          response.data.saldo.toString().split(":")[0] +
          ":" +
          response.data.saldo.toString().split(":")[1];

        let tempoConsumido =
          response.data.tempoConsumido.toString().split(":")[0] +
          ":" +
          response.data.tempoConsumido.toString().split(":")[1];

        let tempoDisponivel =
          response.data.tempoDisponivel.toString().split(":")[0] +
          ":" +
          response.data.tempoDisponivel.toString().split(":")[1];

        let saldoExcedido =
          response.data.saldoExcedido.toString().split(":")[0] +
          ":" +
          response.data.saldoExcedido.toString().split(":")[1];

        let consumoAtendimentoEspecial =
          response.data.consumoAtendimentoEspecial;

        this.setState(
          {
            saldo: saldoTemp,
            tempoConsumido: tempoConsumido,
            tempoDisponivel: tempoDisponivel,
            consumoAtendimentoEspecial,
            saldoExcedido: saldoExcedido,
          },
          () => {
            localStorage.setItem("saldo", saldoTemp);
            localStorage.setItem("tempoConsumido", tempoConsumido);
            localStorage.setItem("tempoDisponivel", tempoDisponivel);
            localStorage.setItem(
              "consumoAtendimentoEspecial",
              consumoAtendimentoEspecial
            );
            localStorage.setItem("saldoExcedido", saldoExcedido);
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  async loadAtendimentos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/atendimentos`,
        this.props.parameters()
      )
      .then((response) => {
        let graficoAtendimentos = this.state.data;

        graficoAtendimentos.datasets[0].data = [
          response.data.totalTicketsAberto,
          response.data.totalTicketsFinalizados,
        ];
        graficoAtendimentos.labels = [];
        graficoAtendimentos.labels.push(
          `Pendentes (${response.data.totalTicketsAberto})`
        );
        graficoAtendimentos.labels.push(
          `Finalizados (${response.data.totalTicketsFinalizados})`
        );

        this.setState(
          {
            data: graficoAtendimentos,
            totalTickets: response.data.totalTickets,
          },
          () => {
            localStorage.setItem("data", JSON.stringify(response.data));
            localStorage.setItem("totalTickets", response.data.totalTickets);
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  async loadHistoricoAtendimento() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/historico-atendimento`,
        this.props.parameters()
      )
      .then((response) => {
        let graficoConsultoria = this.state.dataGrafico;

        graficoConsultoria.labels = [];
        graficoConsultoria.datasets[0].data = [];

        for (let item of response.data.historicoConsumoAtendimento) {
          graficoConsultoria.labels.push(item.mes);
          graficoConsultoria.datasets[0].data.push(item.tempoTotal);
        }

        this.setState(
          {
            dataGrafico: graficoConsultoria,
          },
          () => {
            localStorage.setItem(
              "dataGrafico",
              JSON.stringify(response.data.historicoConsumoAtendimento)
            );
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  async loadIndicadoresConsultoria() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/indicadores-consultoria`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState(
          {
            indicadores: response.data.indicadores,
          },
          () => {
            localStorage.setItem(
              "indicadores",
              JSON.stringify(response.data.indicadores)
            );
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  async loadIndicadoresPendencia() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/indicadores-pendencia`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState(
          {
            indicadoresPendencias: response.data.indicadoresPendencias,
          },
          () => {
            localStorage.setItem(
              "indicadoresPendencias",
              JSON.stringify(response.data.indicadoresPendencias)
            );
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  async loadMensagens() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/dashboard/mensagens`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState(
          {
            mensagens: response.data.mensagens,
          },
          () => {
            localStorage.setItem(
              "mensagens",
              JSON.stringify(response.data.mensagens)
            );
          }
        );
      })
      .catch((error) => {
        // this.props.handlingErrorsApi(JSON.stringify(err), this.props)
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            this.props.logout();
            this.props.history.push("login");
          } else {
            alert(error.response.data.error);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }

  toogleMensagem(idMensagem) {
    let mensagemAberta = null;
    if (this.state.mensagemAberta !== idMensagem) {
      mensagemAberta = idMensagem;
    }
    this.setState({ mensagemAberta });
  }

  async handleClickMensagem({ id, lido }) {
    if (!lido) {
      //TODO: AXIOS
      await axios
        .put(
          `${this.props.getBaseUrlApi()}/mensagem/ler/${id}`,
          {},
          this.props.parameters()
        )
        .then();
    }
    this.toogleMensagem(id);
  }

  render() {
    const ItemIndicadores = (props) => {
      let style = {};

      if (
        props.titulo === "Atendimento Especial" ||
        props.titulo === "Desenvolvimento sob medida"
      ) {
        style = { padding: "0px 18px 0px 20px" };
      } else {
        style = { padding: "0px 18px 20px 20px" };
      }

      let exibeHoras = false;

      if (!props.hasOwnProperty("exibeHoras")) {
        exibeHoras = true;
      } else {
        exibeHoras = props.exibeHoras;
      }

      return (
        <Col sm={12} style={style}>
          <div className="title-dashboard">
            {props.titulo}{" "}
            {props.tooltip ? (
              <React.Fragment>
                <i
                  class="fa fa-question-circle"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cobrança no mês subsequente"
                  id={`tooltip-${props.id}`}
                ></i>
              </React.Fragment>
            ) : (
              ""
            )}{" "}
            <br />
            <span className="subtitle-dashboard">{props.subtitulo}</span>
          </div>
          <div className={`value-dashboard dashboard-${props.color}`}>
            <span>{props.valor}</span>{" "}
            {exibeHoras === true && <span className="text-horas">Horas</span>}
          </div>
        </Col>
      );
    };
    return (
      <div id="contentSite" className={className}>
        <div className="Example_container-home">
          <div className="container">
            <Row>
              <Col sm={6}>
                <div className="card card-home">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%",
                      paddingBottom: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    Indicadores de Atendimento
                  </span>
                  <ItemIndicadores
                    titulo="Franquia mensal"
                    subtitulo="Atendimentos de Suporte e Help-Desk"
                    valor={this.state.tempoDisponivel}
                    color="blue"
                    tooltip={false}
                  />

                  <ItemIndicadores
                    titulo="Consumo mensal"
                    subtitulo="Total utilizado no franquia mensal"
                    valor={this.state.tempoConsumido}
                    color="blue"
                    tooltip={false}
                  />

                  <ItemIndicadores
                    titulo="Saldo disponível"
                    subtitulo="Saldo disponível da franqua mensal"
                    valor={this.state.saldo}
                    color="blue"
                    tooltip={false}
                  />

                  <ItemIndicadores
                    titulo="Saldo excedente"
                    subtitulo="Total utilizado acima da franquia mensal"
                    valor={this.state.saldoExcedido}
                    color="red"
                    tooltip={true}
                  />

                  <ItemIndicadores
                    titulo="Atendimento Especial"
                    subtitulo="Total utilizado em horário especial"
                    valor={this.state.consumoAtendimentoEspecial}
                    color="red"
                    tooltip={true}
                  />

                  {/* <ItemIndicadores
                    titulo="Atendimento presencial"
                    subtitulo="Total utilizado em consultoria"
                    valor={this.state.consumoConsultoria}
                    color="green"
                    tooltip={true}
                  /> */}
                </div>
              </Col>

              <Col sm={6}>
                <div className="card card-home">
                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      position: "relative",
                      minHeight: "20px",
                    }}
                  >
                    <span
                      className="title-dashboard"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        position: "absolute",
                        fontWeight: "bold",
                      }}
                    >
                      Atendimentos
                    </span>
                    <div style={{ position: "absolute", right: "10px" }}>
                      <i
                        class="fa fa-question-circle"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="A fração mínima de apontamento de tempo é de 15 minutos. Não conformidades, não deduzem da franquia mensal."
                        id={`tooltip-grafico_atendimento`}
                      ></i>
                    </div>{" "}
                  </div>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px",
                    }}
                  >
                    Tickets do mês atual ({this.state.totalTickets})
                  </span>
                  <Doughnut
                    data={this.state.data}
                    width={95}
                    height={45}
                    options={{
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                    }}
                  />

                  {/* <div
                    style={{
                      color: "rgb(150, 153, 162)",
                      textAlign: "center",
                      padding: "10px 0px 0px 0px"
                    }}
                  >
                    A fração mínima de apontamento de tempo é de 15 minutos{" "}
                    <br /> Não conformidades, não deduzem da franquia mensal.
                  </div> */}
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <div className="card card-home">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    Atendimento
                  </span>
                  <span
                    className="subtitle-dashboard"
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingBottom: "12px",
                    }}
                  >
                    Total de horas dos ultimos 6 meses
                  </span>

                  <div>
                    <Bar
                      data={this.state.dataGrafico}
                      width={100}
                      height={250}
                      options={{
                        maintainAspectRatio: false,
                        legend: {
                          position: "bottom",
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className="card card-home">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%",
                      paddingBottom: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Indicadores de Consultoria
                  </span>
                  <ItemIndicadores
                    titulo="Atendimento Presencial"
                    subtitulo="Total em consultoria presencial"
                    valor={
                      this.state.indicadores.indicadorAtendimentoPresencial
                    }
                    color="green"
                    tooltip={true}
                  />

                  <ItemIndicadores
                    titulo="Atendimento Presencial Especial"
                    subtitulo="Total de consultoria em horário especial"
                    valor={
                      this.state.indicadores
                        .indicadorAtendimentoPresencialEspecial
                    }
                    color="green"
                    tooltip={true}
                  />

                  <ItemIndicadores
                    titulo="Atendimento Remoto"
                    subtitulo="Total em consultoria remota"
                    valor={this.state.indicadores.indicadorRemoto}
                    color="green"
                    tooltip={true}
                  />

                  <ItemIndicadores
                    titulo="Atendimento Remoto especial"
                    subtitulo="Total em consultoria remota em horário especial"
                    valor={this.state.indicadores.indicadorRemotoEspecial}
                    color="green"
                    tooltip={true}
                  />

                  <ItemIndicadores
                    titulo="Desenvolvimento sob medida"
                    subtitulo="Total em desenvolvimento sob medida"
                    valor={this.state.indicadores.indicadorDevSobMedida}
                    color="green"
                    tooltip={true}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <div className="card card-home">
                  <span
                    className="title-dashboard"
                    style={{
                      textAlign: "center",
                      float: "left",
                      width: "100%",
                      paddingBottom: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Indicadores de Pendências
                  </span>
                  <ItemIndicadores
                    titulo="Priori"
                    subtitulo="Total de pendências"
                    valor={this.state.indicadoresPendencias.priori}
                    color="green"
                    tooltip={false}
                    exibeHoras={false}
                  />

                  <ItemIndicadores
                    titulo="Cliente"
                    subtitulo="Total de pendências"
                    valor={this.state.indicadoresPendencias.cliente}
                    color="green"
                    tooltip={false}
                    exibeHoras={false}
                  />

                  <ItemIndicadores
                    titulo="Terceiros"
                    subtitulo="Total de pendências"
                    valor={this.state.indicadoresPendencias.terceiros}
                    color="green"
                    tooltip={false}
                    exibeHoras={false}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="card card-home">
                  <span
                    className="title-dashboard"
                    style={{
                      fontSize: "18px",
                      padding: "0px 20px 20px 20px",
                      float: "left",
                      textAlign: "center",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    Mensagens
                  </span>

                  <div style={{ float: "left", width: "100%" }}>
                    <table className="table table-hover table-bordered">
                      <tbody>
                        {this.state.mensagens.map((mensagem, key) => (
                          <tr
                            key={key}
                            onClick={() => this.handleClickMensagem(mensagem)}
                          >
                            <td
                              style={
                                mensagem.lido ? {} : { fontWeight: "bold" }
                              }
                            >
                              {mensagem.titulo} <br />
                              <div
                                className="descricao-mensagem-dashboard"
                                style={
                                  this.state.mensagemAberta === mensagem.id
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                {mensagem.descricao}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
