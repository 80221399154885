import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import "./SideBar.css";
import {UncontrolledTooltip} from 'reactstrap';

const style = {
  padding: "10px",
  fontSize: "16px"
};

const SideBar = (props) => {

  const [notificacao, setNotificacao] = useState(false);
  const [menuCompacto, setMenuCompacto] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => { 
      axios.get(`${props.getBaseUrlApi()}/notificacoes/existe-notificacao`, props.parameters())
      .then(response => {  
        setNotificacao(response.data.existeNotificacao);
        localStorage.setItem('existeNotificacao', response.data.existeNotificacao);
      })
      .catch(err => {
        console.clear()
        console.log(props)
      });  
    }, 60000);//1 minuto
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let menuCompactoStorage = localStorage.getItem("menuCompacto");

    if(menuCompactoStorage === null){
      localStorage.setItem('menuCompacto', false);
    }else{
      menuCompactoStorage = menuCompactoStorage === 'true' ? true : false
      setMenuCompacto(menuCompactoStorage)
      if(menuCompactoStorage){
        document.getElementById('contentSite').classList.add("contentSite-menu-compacto")
        document.getElementById('navBar-app').classList.add("contentSite-menu-compacto")
      }else{
        document.getElementById('contentSite').classList.remove("contentSite-menu-compacto")
        document.getElementById('navBar-app').classList.remove("contentSite-menu-compacto")
      }
    }
  }, []);

  return (
    <React.Fragment>



    <div id="Example_sidebar" className={`${menuCompacto ? 'sidebar-short': ''}`}>

    <div className={`teste ${menuCompacto ? 'sidebar-short': ''}`} onClick={() => {
      let menuCompacto1 = !menuCompacto;
      setMenuCompacto(menuCompacto1);
      localStorage.setItem('menuCompacto', menuCompacto1)
      if(menuCompacto1){
        document.getElementById('contentSite').classList.add("contentSite-menu-compacto")
        document.getElementById('navBar-app').classList.add("contentSite-menu-compacto")
      }else{
        document.getElementById('contentSite').classList.remove("contentSite-menu-compacto")
        document.getElementById('navBar-app').classList.remove("contentSite-menu-compacto")
      }
    }}>
        <Link to={'/home'} className="navbar-brand Example_navbar-brand">
          <img src="/images/logo-small.png" className="navbar-logo"/> {!menuCompacto && <span>SAC Portal</span>}
        </Link>
        <div className="" style={{cursor:"pointer"}}>
          <div className="teste1"></div>
          <div className="teste1"></div>
          <div className="teste1"></div>
        </div>
      </div>
      
      <ul style={{ color: "#FFF", paddingTop: "70px" }}>
        <Link to={"/home"} style={{ textDecoration: "none", color: "inherit" }}>
          <li className={`item-menu-li ${props.itemActive === 'home' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-tachometer" id="itemDashboard" aria-hidden="true" /> {!menuCompacto && <span>&nbsp;Dashboard</span>}
          </li>
        </Link>
        <UncontrolledTooltip
          placement="right"
          target="itemDashboard"
        >
          Dashboard
        </UncontrolledTooltip>

        <Link
          to={"/tickets/novo"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'ticketsNovo' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-ticket" id="itemTickets2" aria-hidden="true" />{!menuCompacto && <span>&nbsp;Novo Ticket</span>}
          </li>
        </Link>
        <UncontrolledTooltip
          placement="right"
          target="itemTickets2"
        >
          Novo Ticket
        </UncontrolledTooltip>
        <Link
          to={"/tickets"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'tickets' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-ticket" id="itemTickets" aria-hidden="true" />{!menuCompacto && <span>&nbsp;Tickets</span>}
          </li>
        </Link>
        <UncontrolledTooltip
          placement="right"
          target="itemTickets"
        >
          Tickets
        </UncontrolledTooltip>
        <Link
          to={"/requisitos"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'requisitos' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-list-ul" id="itemRequisitos" aria-hidden="true" />{!menuCompacto && <span>&nbsp;Requisitos</span>}
          </li>
        </Link>
        <UncontrolledTooltip
          placement="right"
          target="itemRequisitos"
        >
          Requisitos
        </UncontrolledTooltip>
        <Link
          to={"/cobrancas"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'cobrancas' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-file" id="itemCobrancas" aria-hidden="true" /> {!menuCompacto && <span>&nbsp;Cobranças</span>}
          </li>
        </Link>
        <UncontrolledTooltip
          placement="right"
          target="itemCobrancas"
        >
          Cobranças
        </UncontrolledTooltip>
      
        <Link
          to={"/admin"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'admin' ? 'item-menu-li-active' : ''} ${menuCompacto ? 'text-center' : ''}`} style={style}>
            <i className="fa fa-cogs" id="itemConfiguracoes" aria-hidden="true" /> {!menuCompacto && <span>&nbsp;Configurações</span>}
          </li>
        </Link>     
        <UncontrolledTooltip
          placement="right"
          target="itemConfiguracoes"
        >
          Configurações
        </UncontrolledTooltip>
      
        {/* <Link
          to={"/notificacoes"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className={`item-menu-li ${props.itemActive === 'notificacao' ? 'item-menu-li-active' : ''}`} style={style}>
            <i className="fa fa-bell" aria-hidden="true" /> Notificações&nbsp; {notificacao ? (<i class="fa fa-circle icon-alert-notificacao" aria-hidden="true"></i>): ''}
          </li>
        </Link>
        <Link
          to={"/logout"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <li className="item-menu-li" style={style}>
            <i className="fa fa-sign-out" aria-hidden="true" /> Sair
          </li>
        </Link> */}
      </ul>
    </div>
    
    </React.Fragment>
  );
};

export default SideBar;
